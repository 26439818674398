import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
    Button, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { sendSupplierReminder } from '../../reducers/email.reducer';
import { selectEmailLoading } from '../../selectors/email.selector';
import { selectEmail, selectOrganisationId } from '../../selectors/login.selector';

const SupplierReminderButton = ({ supplier }) => {
    const dispatch = useDispatch();
    const loading = useSelector(selectEmailLoading);
    const email = useSelector(selectEmail);
    const organisationId = useSelector(selectOrganisationId);
    const [open, setOpen] = useState(false);
    const [selectedEmails, setSelectedEmails] = useState({});

    const additionalEmails = [
        'fachon.perry@stitchfix.com',
        'rafi.sultan_bhuiya@stitchfix.com',
        'stitchfix@elm-ai.com'
    ];

    const handleClick = () => {
        setSelectedEmails({ [email]: true }); // Default to current user's email selected
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEmailChange = (email) => {
        setSelectedEmails(prev => ({
            ...prev,
            [email]: !prev[email]
        }));
    };

    const handleSend = () => {
        const emailsToSend = Object.entries(selectedEmails)
            .filter(([_, isSelected]) => isSelected)
            .map(([email]) => email);

        emailsToSend.forEach(emailAddress => {
            dispatch(sendSupplierReminder({
                organisationId,
                supplierId: supplier._id,
                email: emailAddress
            }));
        });

        setOpen(false);
    };

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={handleClick}
                disabled={loading}
            >
                Send Reminder
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Select Emails for Reminder</DialogTitle>
                <DialogContent>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedEmails[email] || false}
                                    onChange={() => handleEmailChange(email)}
                                />
                            }
                            label={`${email} (Current User)`}
                        />
                        {additionalEmails.map((emailAddress) => (
                            <FormControlLabel
                                key={emailAddress}
                                control={
                                    <Checkbox
                                        checked={selectedEmails[emailAddress] || false}
                                        onChange={() => handleEmailChange(emailAddress)}
                                    />
                                }
                                label={emailAddress}
                            />
                        ))}
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleSend} 
                        color="primary" 
                        disabled={!Object.values(selectedEmails).some(Boolean)}
                    >
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SupplierReminderButton;
