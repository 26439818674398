import React from "react";
import { Grid, Box, Card } from "@mui/material";
import RiskScatterPlot from "./RiskDetails/riskScatterPlot.component";
import FactoryRiskDashboard from "./RiskDetails/factoryRiskDashboard.component";

const RiskAnalytics = () => {
  return (
    <Box sx={{ 
      display: "flex", 
      height: "calc(100vh - 300px)", 
      padding: { xs: "16px 24px", md: "16px 40px" } 
    }}>
      <Card
        sx={{
          pl: 4,
          py: 4,
          mt: "18px",
          flex: 1,
          overflow: "hidden",

          borderRadius: "12px",
          border: "1px solid var(--Gray-200, #EAECF0)",
          background: "var(--Base-White, #FFF)",
          boxShadow:
            "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
        }}
      >
        <Grid container spacing={2} sx={{ height: '100%' }}>
          <Grid item xs={7} sx={{ height: '100%' }}>
            <RiskScatterPlot />
          </Grid>
          <Grid item xs={5} sx={{ height: '100%', borderLeft: '1px solid rgba(0, 0, 0, 0.12)' }}>
            <FactoryRiskDashboard />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default RiskAnalytics;
