import { createSelector } from "@reduxjs/toolkit";

const selectAnalytics = (state) => state.analytics;

export const selectAnalyticsUrl = createSelector(
  [selectAnalytics],
  (analytics) => analytics.url
);

export const selectAnalyticsLoading = createSelector(
  [selectAnalytics],
  (analytics) => analytics.loading
);

export const selectAnalyticsError = createSelector(
  [selectAnalytics],
  (analytics) => analytics.error
);
