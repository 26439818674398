import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Button,
  Skeleton,
} from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";

import { debounce } from "lodash";
import moment from "moment";
import TopBreadcrumbs from "./topBreadcrumbs.component";
import {
  getFactoryById,
  getListOfAllFactories,
  getSearchResultOfFactories,
} from "../reducers/factory.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { selectOrganisationId } from "../selectors/login.selector";
import {
  selectFactoryCache,
  selectFactoryList,
  selectSearchCount,
  selectSearchResultsFactoryList,
  selectTotalCount,
} from "../selectors/factory.selector";
import { selectLoadingState } from "../selectors/misc.selector";

const FactoryTableDataGrid = () => {
  const dispatch = useDispatch();
  const factoriesList = useSelector(selectFactoryList);
  const isLoading = useSelector(selectLoadingState);
  const totalCount = useSelector(selectTotalCount);
  const navigateToFactoryDetails = (organisationId, supplierId, factoryId) => {
    dispatch(getFactoryById({ supplierId, factoryId }));
    navigate(`/organisations/${organisationId}/suppliers/${supplierId}/factories/${factoryId}`);
  };

  const rows = factoriesList.map((factory) => {
    return {
      id: factory?.factoryId,
      factoryName: factory?.name,
      country: factory?.metadata?.country,
      status: factory?.metadata?.status,
      tier: factory?.metadata?.tier,
      associatedSupplier: factory?.supplierName,
      factoryApprovalDate: factory?.metadata?.factoryApprovalDate ? moment(factory.metadata.factoryApprovalDate).format('YYYY-MM-DD') : 'N/A',
      nextReportDate: factory?.metadata?.nextReportDate ? moment(factory.metadata.nextReportDate).format('YYYY-MM-DD') : 'N/A',
      supplierId: factory?.supplierId,
      organisationId: factory?.organisationId,
      brandBusiness: `${factory?.metadata?.productCategories || ''} ${factory?.businessUnit ? `(${factory.businessUnit})` : ''}`.trim(),
      factoryId: factory?.metadata?.factoryId,
      riskScore: factory?.riskScore,
    };
  });

  const columns = [
    { field: "factoryName", headerName: "Factory Name", width: 150 },
    {
      field: "country",
      headerName: "Country",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      type: 'singleSelect',
      valueOptions: ['Approved', 'Pending', 'Inactive'],
    },
    {
      field: "tier",
      headerName: "Tier",
      type: "number",
      width: 75,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "associatedSupplier",
      headerName: "Associated Supplier",
      width: 225,
    },
    {
      field: "factoryApprovalDate",
      headerName: "Factory Approval Date",
      width: 150,
    },
    {
      field: "nextReportDate",
      headerName: "Next Audit",
      width: 150,
    },
    {
      field: "brandBusiness",
      headerName: "Brand/Business",
      width: 150,
    },
    {
      field: "factoryId",
      headerName: "Factory ID",
      width: 150,
    },
    {
      field: "riskScore",
      headerName: "Risk Score",
      width: 150,
    },
    {
      field: "details",
      headerName: "",
      width: 100,
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={() => {
            console.log("params.row.supplierId", params.row);
            navigateToFactoryDetails(params.row.organisationId, params.row.supplierId, params.row.id);
          }}
          sx={{
            backgroundColor: "transparent",
            color: "#6172F3",
            borderColor: "#6172F3",
            "&:hover": {
              backgroundColor: "#f0f0f0",
              borderColor: "#5667e2",
            },
            textTransform: "none",
            minWidth: "79px",
            height: "40px",
            borderRadius: "8px",
          }}
          size="small"
        >
          Details
        </Button>
      ),
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {},
    },
    filter: {
      filterModel: {
        items: [{ field: 'status', operator: 'is', value: 'Inactive' }],
      },
    },
  };

  const dataGridFormat = {
    columns,
    rows,
    initialState,
  };

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredFactories, setFilteredFactories] = useState([]);
  const [count, setCount] = useState(totalCount);

  const factoryCache = useSelector((state) => selectFactoryCache(state, page));
  const navigate = useNavigate();

  const organisationId = useSelector(selectOrganisationId);
  const searchCount = useSelector(selectSearchCount);
  const searchResults = useSelector(selectSearchResultsFactoryList);

  useEffect(() => {
    if (searchTerm !== "") {
      setFilteredFactories(searchResults);
    } else {
      setFilteredFactories(factoriesList);
    }
  }, []);
  useEffect(() => {
    if (factoryCache) {
      setFilteredFactories(factoryCache);
    } else {
      const limit = 8;
      const offset = (page - 1) * limit;
      dispatch(getListOfAllFactories({ page, limit, offset, organisationId }));
    }
  }, [page, factoryCache]);
  const debouncedFetchFactories = useCallback(
    debounce((term) => {
      dispatch(getSearchResultOfFactories({ search: term }));
    }, 100),
    []
  );

  const location = useLocation();

  return (
    <Box sx={{ p: 2, mr: 2 }}>
      <TopBreadcrumbs />
      <Typography variant="h6" style={{ fontSize: 20, fontWeight: 600 }}>
        Factories
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}
      >
        Manage your factories here
      </Typography>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 2 }}>
      </Box>
      <div style={{ width: "80vw"}}>
        <div style={{ height: "70vh", width: "100%", overflowX: "auto" }}>
          {isLoading ? (
            <Box sx={{ width: "100%" }}>
              {[...Array(10)].map((_, index) => (
                <Skeleton
                  key={index}
                  height={60}
                  animation="wave"
                  sx={{ my: 1 }}
                />
              ))}
            </Box>
          ) : (
            <DataGridPro
              {...dataGridFormat}
              disableColumnSelector
              disableDensitySelector
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              getRowHeight={() => "auto"}
              sx={{
                height: 'calc(100vh - 200px)',
                borderRadius: "8px",
                padding: "16px",
                "& .MuiDataGrid-cell": {
                  padding: "8px",
                },
                "& .MuiDataGrid-row": {
                  maxHeight: "none !important",
                  cursor: "pointer",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#f0f0f0",
                  color: "#333",
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnHeader": {
                  padding: "16px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
              }}
              onRowClick={(params) => {
                navigateToFactoryDetails(params.row.organisationId, params.row.supplierId, params.row.id);
              }}
            />
          )}
        </div>
      </div>
    </Box>
  );
};

export default FactoryTableDataGrid;
