import React from "react";
import { Grid } from "@mui/material";

import LeftSideNavbar from "../components/leftSideNavbar.component";
import SupplierTableDataGrid from "../components/SupplierDetails/supplierTableDataGrid.component";

const Suppliers = () => {
  return (
    <Grid container>
      <Grid item xs={2}>
        <LeftSideNavbar />
      </Grid>
      <Grid item xs={10}>
        <Grid container direction="column">
          {/* <SupplierTable /> */}
          <SupplierTableDataGrid />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Suppliers;
