import React, { useState } from 'react';
import { 
    Button, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrganisationId, selectEmail } from '../../selectors/login.selector';
import { sendSupplierReminder } from '../../reducers/email.reducer';

const SendReminderToAllVendorsButton = () => {
  const dispatch = useDispatch();
  const organisationId = useSelector(selectOrganisationId);
  const email = useSelector(selectEmail);
  const [open, setOpen] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState({});

  const additionalEmails = [
    'fachon.perry@stitchfix.com',
    'rafi.sultan_bhuiya@stitchfix.com',
    'stitchfix@elm-ai.com'
  ];

  const handleClick = () => {
    setSelectedEmails({ [email]: true }); // Default to current user's email selected
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEmailChange = (email) => {
    setSelectedEmails(prev => ({
      ...prev,
      [email]: !prev[email]
    }));
  };

  const handleSend = () => {
    const emailsToSend = Object.entries(selectedEmails)
      .filter(([_, isSelected]) => isSelected)
      .map(([email]) => email);

    emailsToSend.forEach(emailAddress => {
      dispatch(sendSupplierReminder({ 
        organisationId, 
        email: emailAddress 
      }));
    });

    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        sx={{
          color: "#6172F3",
          borderColor: "#6172F3",
          "&:hover": {
            color: "white",
            backgroundColor: "#5667e2",
            borderColor: "#5667e2"
          },
          textTransform: "none",
          width: "200px",
          height: "40px",
          borderRadius: "8px",
        }}
      >
        Remind All Vendors
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select Emails for All Vendors Reminder</DialogTitle>
        <DialogContent>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedEmails[email] || false}
                  onChange={() => handleEmailChange(email)}
                />
              }
              label={`${email} (Current User)`}
            />
            {additionalEmails.map((emailAddress) => (
              <FormControlLabel
                key={emailAddress}
                control={
                  <Checkbox
                    checked={selectedEmails[emailAddress] || false}
                    onChange={() => handleEmailChange(emailAddress)}
                  />
                }
                label={emailAddress}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleSend} 
            color="primary" 
            disabled={!Object.values(selectedEmails).some(Boolean)}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SendReminderToAllVendorsButton;
