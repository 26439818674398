import React from "react";
import { Grid } from "@mui/material";

import SupplierTable from "../components/supplierTable.component";
import LeftSideNavbar from "../components/leftSideNavbar.component";
import FactoryTable from "../components/factoryTable.component";
import FactoryTableDataGrid from "../components/factoryTableDataGrid.component";

const Factories = () => {
  return (
    <Grid container sx={{ overflowX: 'hidden' }}>
      <Grid item xs={2}>
        <LeftSideNavbar />
      </Grid>
      <Grid item xs={10}>
        <Grid container direction="column" sx={{ overflowX: 'hidden' }}>
          {/* <FactoryTable /> */}
          <FactoryTableDataGrid />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Factories;