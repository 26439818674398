import React from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import RiskCard from "../../riskCard.component";
import {
  selectFactoryData,
  selectFactoryList,
} from "../../../selectors/factory.selector";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ComponentStyles } from "../../../styles";
import moment from "moment";
const FactoryRiskDashboard = () => {
  const factoryData = useSelector(selectFactoryData);
  const facilityList = useSelector(selectFactoryList);

  // const selectedFactory = factoryData ? facilityList.find(factory => factory.factoryId === factoryData._id) : null;

  return (
    <Box
      sx={{
        maxWidth: "90%",
        margin: "auto",
        maxHeight: "100%",
        overflowY: factoryData ? "auto" : "hidden",
        pb: 4,
        alignContent: "center",
        pr: 2,
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          background: "white",
          zIndex: 1,
          py: "16px",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: "4px" }}
        >
          <Grid item>
            <Typography sx={ComponentStyles.fieldName}>Factory</Typography>
          </Grid>

          <Grid item>
            {factoryData && (
              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  px: "8px",
                  borderRadius: "8px",
                  border: "1px solid var(--Brand-300, #A4BCFD)",
                  background: "#FFF",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  color: "#3538CD",
                  fontSize: "14px",
                  fontWeight: 600,
                }}

              >
                <Link
                  to={`/organisations/6695658d1b99ac80ca7a2d64/suppliers/${factoryData?.supplierId}/factories/${factoryData?._id}`} // special for stitchFix with org id hard coded.
                  style={{
                    fontWeight: 500,
                    fontSize: 14,
                    textDecoration: "none",
                    color: "inherit",
                    textTransform: "none",
                  }}
                >
                  To Factory Page
                </Link>
              </Button>
            )}
          </Grid>
        </Grid>
        {factoryData ? (
          <Grid item>
            <Typography
              sx={{
                color: "#182230",
                fontWeight: 700,
                fontSize: "18px",
                mb: 2,
              }}
            >
              {factoryData?.name}
            </Typography>
          </Grid>
        ) : (
          <Grid item>
            <Typography
              sx={{
                color: "#182230",
                fontWeight: 700,
                fontSize: "18px",
              }}
            >
              Select a factory to view risk profile
            </Typography>
          </Grid>
        )}
      </Box>

      {console.log(factoryData)}

      {/* StitchFix related */}

      {factoryData?.risk?.riskClass && (
        <RiskCard
          key={"factoryInfoOnRiskMap"}
          title={"Factory profile"}
          riskScore={
            factoryData?.risk?.riskClass ?? "No Data" // TODO: need to change key later the category comes.
          }
          details={[
            {
              label: "Status",
              subLabel: factoryData?.metadata?.status || "-",
              value: "",
            },
            {
              label: "Risk Score",
              subLabel: factoryData?.risk?.riskScore || "-",
              value: "",
            },
            {
              label: "Tier",
              subLabel: factoryData?.metadata?.tier || "-",
              value: "",
            },
            {
              label: "Product Categories",
              subLabel: factoryData?.metadata?.productCategories || "-",
              value: "",
            },

          ]}
        />
      )}


    </Box>
  );
};

export default FactoryRiskDashboard;
