import React, { useEffect, useState, useCallback } from "react";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";

import {
  Box,
  Typography,
  Divider,
  Button,
  Drawer,
  Chip,
} from "@mui/material";
import TopBreadcrumbs from "../topBreadcrumbs.component";

import { debounce } from "lodash";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  getListOfAllSuppliers,
  getSearchResultOfSuppliers,
  getSupplierById,
} from "../../reducers/supplier.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
  selectSearchCount,
  selectSearchResultsSupplierList,
  selectSupplierCache,
  selectSupplierList,
  selectTotalCount,
} from "../../selectors/supplier.selector";
import { getListOfAllFactories } from "../../reducers/factory.reducer";
import { BorderLeft } from "@mui/icons-material";
import CreateSupplierForm from "../createSupplier.component";
import SendReminderToAllVendorsButton from "../SupplierReminder/SendReminderToAllVendorsButton";

// import { ComponentStyles } from "../../styles";
const SupplierTableDataGrid = () => {
  const dispatch = useDispatch();
  const suppliersList = useSelector(selectSupplierList);
  const navigateToSupplierDetails = (supplierId) => {
    console.log("supplierId", supplierId);
    dispatch(getSupplierById(supplierId));
    // dispatch(getListOfAllFactories({ limit: 8, offset: 0, supplierId }));
    navigate(`${location.pathname}/${supplierId}`);
  };
  const rows = suppliersList.map((supplier) => {
    return {
      id: supplier?.supplierId, // Unique ID for the grid's row key
      supplierName: supplier?.name,
      businessUnit: supplier?.businessUnit,
      vendorStatus: supplier?.metadata?.vendorStatus,
      totalSiteCount: supplier?.factoryData?.totalFactories,
      tier1: supplier?.factoryData?.tier1,
      tier2: supplier?.factoryData?.tier2,
      actionAlert: supplier?.actionAlert,
    };
  });

  const columns = [
    { field: "supplierName", headerName: "Supplier Name", flex: 1 },
    { field: "businessUnit", headerName: "Business Unit", flex: 1 },
    {
      field: "vendorStatus",
      headerName: "Vendor Status",
      flex: 1,
      renderCell: (params) => {
        console.log("params", params);
        return (
          <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
            <Typography
              sx={{
                color: params?.row?.vendorStatus?.includes("Approved")
                  ? "green"
                  : "inherit",
                fontWeight: 400,
                fontSize: "0.875rem",
              }}
            >
              {params.row.vendorStatus}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "totalSiteCount",
      headerName: "Total Factory",
      type: "number",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "tier1",
      headerName: "Tier 1",
      type: "number",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "tier2",
      headerName: "Tier 2",
      type: "number",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actionAlert",
      headerName: "Action Alert",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.actionAlert}
          sx={{
            borderRadius: "16px",
            fontWeight: 500,
            fontSize: 12,
            backgroundColor:
              params.row.actionAlert == "Yes" ? "#FECDCA" : "#F2F4F7",
            color: params.row.actionAlert == "Yes" ? "#B42318" : "default",
            cursor: "pointer",
          }}
        />
      ),
    },
    {
      field: ".",
      headerName: "",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => {
            console.log("params.row.supplierId", params.row);
            navigateToSupplierDetails(params.row.id);
          }}
          sx={{
            backgroundColor: "#6172F3",
            color: "white",
            "&:hover": {
              backgroundColor: "#5667e2",
              fontWeight: "bold",
            },
            textTransform: "none",
            width: "79px",
            height: "40px",
            borderRadius: "8px",
          }}
          size="small"
        >
          Details
        </Button>
      ),
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // This can be used to hide or show columns by default
      },
    },
  };

  const dataGridFormat = {
    columns,
    rows,
    initialState,
  };

  const totalCount = useSelector(selectTotalCount);
  const searchResults = useSelector(selectSearchResultsSupplierList);
  const searchCount = useSelector(selectSearchCount);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [count, setCount] = useState(totalCount);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const supplierCache = useSelector((state) =>
    selectSupplierCache(state, page)
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (searchTerm !== "") {
      setCount(searchCount);
    } else {
      setCount(totalCount);
    }
  }, [totalCount, searchCount]);
  useEffect(() => {
    if (searchTerm !== "") {
      setFilteredSuppliers(searchResults);
    } else {
      setFilteredSuppliers(suppliersList);
    }
  }, []);
  useEffect(() => {
    if (supplierCache) {
      setFilteredSuppliers(supplierCache);
    } else {
      const limit = 8;
      const offset = (page - 1) * limit;
      dispatch(getListOfAllSuppliers({ page, limit, offset }));
    }
  }, [page, supplierCache, dispatch]);

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const location = useLocation();

  return (
    <Box sx={{ p: 2, mr: 2 }}>
      <TopBreadcrumbs />
      <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
        <div>
          <Typography variant="h6" style={{ fontSize: 20, fontWeight: 600 }}>
            Suppliers
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}
          >
            Manage your suppliers here
          </Typography>
        </div>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center", mr: "48px" }}>
          <Button
            variant="outlined"
            onClick={() => setIsDrawerOpen(true)}
            sx={{
              color: "#6172F3",
              borderColor: "#6172F3",
              "&:hover": {
                color: "white",
                backgroundColor: "#5667e2",
                borderColor: "#5667e2"
              },
              textTransform: "none",
              width: "200px",
              height: "40px",
              borderRadius: "8px",
            }}
          >
            Create New Vendor
          </Button>
          <SendReminderToAllVendorsButton />
        </Box>
      </div>
      
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 2 }}>
        {/* <TextField
          label="Search supplier"
          value={searchTerm}
          onChange={handleSearch}
          variant="outlined"
          size="small"
          sx={{ width: "300px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src="./search.svg" alt="Search" />
              </InputAdornment>
            ),
          }}
        /> */}
      </Box>
      <div style={{ width: "100%" }}></div>
      <div style={{ width: "100%" }}>
        <div style={{ height: 'calc(100vh - 200px)', width: "100%" }}>
          <DataGridPro
            {...dataGridFormat}
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            getRowHeight={() => "auto"}
            sx={{
              height: 'calc(100vh - 200px)',
              borderRadius: "8px",
              padding: "16px",
              "& .MuiDataGrid-cell": {
                padding: "8px",
              },
              "& .MuiDataGrid-row": {
                maxHeight: "none !important",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#f0f0f0",
                color: "#333",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-columnHeader": {
                padding: "16px",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
            }}
          />
        </div>
      </div>
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => { setIsDrawerOpen(false) }}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            maxHeight: "98%",
            width: "90%",
            margin: "0 auto",
            display: "flex",
            justifySelf: "center",
          },
        }}
      >
        <CreateSupplierForm handleDrawerClose={handleDrawerClose} />
      </Drawer>
    </Box>
  );
};

export default SupplierTableDataGrid;
