import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectEmail } from "../../../selectors/login.selector";
import { getValidData, checkInternalEmail } from "../../../utils/lib";
import { updateAuditData } from "../../../reducers/audit.reducer";
import { sendAuditEmail } from "../../../reducers/email.reducer";
import { selectFactoryData } from "../../../selectors/factory.selector";
import { getFactoryById } from "../../../reducers/factory.reducer";

const AuditInformationEditModal = ({
  open = false,
  onClose,
  factoryData,
  auditData,
}) => {
  const initData = {
    auditType: getValidData(auditData?.metadata?.auditType),
    auditStartDate: getValidData(auditData?.metadata?.auditStartDate),
    auditEndDate: getValidData(auditData?.metadata?.auditEndDate),
    auditScheduler: getValidData(auditData?.metadata?.auditScheduler),
    auditFunder: getValidData(auditData?.metadata?.auditFunder),
    auditFirm: getValidData(auditData?.metadata?.auditFirm),
    auditor: getValidData(auditData?.auditor),
    primaryLanguage: getValidData(auditData?.metadata?.primaryLanguage),
    workersLanguage: getValidData(auditData?.metadata?.workersLanguage),
  };
  const [formData, setFormData] = useState(initData);
  const [initialData, setInitialData] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [emailRecipient, setEmailRecipient] = useState("");

  const userEmail = useSelector(selectEmail);
  const isInternalUser = checkInternalEmail(userEmail);
  const selectedFactoryData = useSelector(selectFactoryData);

  useEffect(() => {
    if (Object.keys(factoryData).length === 0) {
      dispatch(
        getFactoryById({
          supplierId: auditData?.supplierId,
          factoryId: auditData?.factoryId,
        })
      );
    }
    factoryData = selectedFactoryData;
  }, [auditData]);

  useEffect(() => {
    setInitialData(initData);
    setFormData(initData);
  }, [auditData, factoryData]);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const changes = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== initialData[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    if (Object.keys(changes).length > 0) {
      dispatch(
        updateAuditData({
          organisationId: auditData?.organisationId,
          supplierId: auditData?.supplierId,
          factoryId: auditData?.factoryId,
          auditId: auditData?._id,
          changes,
        })
      );
    }
    onClose();
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    dispatch(
      updateAuditData({
        organisationId: auditData?.organisationId,
        supplierId: auditData?.supplierId,
        factoryId: auditData?.factoryId,
        auditId: auditData?._id,
        changes: { state: "deleted" },
      })
    );
    setOpenDeleteModal(false);
    onClose();
  };

  const sendAuditCompleteEmail = () => {
    if (!emailRecipient) return;

    dispatch(sendAuditEmail({
      organisationId: auditData?.organisationId,
      supplierId: auditData?.supplierId,
      factoryId: auditData?.factoryId,
      auditId: auditData?._id,
      factoryName: factoryData?.name,
      recipientEmails: [emailRecipient]
    }));
    
    setEmailRecipient("");
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 1200,
          p: 4,
          boxShadow:
            "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",

          border: "1px solid rgba(234, 236, 240, 1)",
          borderRadius: "12px",
          py: 2,
        }}
      >
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: 16,
                color: "#344054",
              }}
            >
              Edit Audit Information
            </Typography>

            {isInternalUser && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  size="small"
                  placeholder="Enter email recipient"
                  value={emailRecipient}
                  onChange={(e) => setEmailRecipient(e.target.value)}
                  sx={{
                    mr: 1,
                    '& .MuiInputBase-root': {
                      height: '32px'
                    }
                  }}
                />
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#585aeb",
                    borderRadius: "8px",
                    textTransform: "none",
                  }}
                  size="small"
                  onClick={sendAuditCompleteEmail}
                  disabled={!emailRecipient}
                >
                  Send Email
                </Button>
              </Box>
            )}
          </Box>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            {Object.keys(initData).map((field, index) => (
              <Grid item xs={4} key={index}>
                <TextField
                  fullWidth
                  label={
                    field === "auditType"
                      ? "Auditor Scheme"
                      : field.replace(/([A-Z])/g, " $1").trim()
                  }
                  name={field}
                  variant="outlined"
                  value={formData[field]}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px", // Set the border-radius here
                      },
                    },
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="error"
                onClick={handleOpenDeleteModal}
                size="large"
                sx={{ textTransform: "none", marginLeft: 1 }}
              >
                Delete Reports
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              type="button"
              onClick={onClose}
              sx={{
                mr: 2,
                borderRadius: "8px",
                textTransform: "none",
                color: "#585aeb",
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#585aeb",
                borderRadius: "8px",
                textTransform: "none",
              }}
            >
              Save Changes
            </Button>
          </Box>
        </form>

        <Dialog
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
        >
          <DialogTitle id="delete-dialog-title">{"Delete Audit Report"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-dialog-description">
              Are you sure you want to delete this audit?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteModal} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="error" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </Modal>
  );
};

export default AuditInformationEditModal;
