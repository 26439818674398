import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Card,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Chip,
  OutlinedInput,
  Autocomplete,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getValidData } from "../../../utils/lib";
import { updateFactoryData } from "../../../reducers/factory.reducer";
import { selectSupplierList } from "../../../selectors/supplier.selector";
import { getListOfAllSuppliers } from "../../../reducers/supplier.reducer";
import CommentSection from "../../commentSection.component";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FactoryInformationEditModal = ({
  open = false,
  onClose,
  internalData,
}) => {
  const getDefaultDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? new Date().toISOString().split('T')[0] : date.toISOString().split('T')[0];
  };

  const initData = {
    factoryId: getValidData(internalData?.metadata?.factoryId, "N/A"),
    parentCompany: getValidData(internalData?.metadata?.supplierName, "Unknown"),
    supplierIds: [
      internalData?.supplierId, // Include the main supplierId
      ...(internalData?.supplierIds || []), // Include additional supplierIds if they exist
    ].filter((id, index, self) => id && self.indexOf(id) === index), // Remove duplicates and null values
    address: getValidData(internalData?.metadata?.address, "No Address Provided"),
    notes: getValidData(internalData?.metadata?.note, []),
    status: getValidData(internalData?.metadata?.status, "Pending"),
    nextAuditDate: getDefaultDate(internalData?.metadata?.nextReportDate),
    factoryName: getValidData(internalData?.name, "Unnamed Factory"),
    agent: getValidData(internalData?.metadata?.agent, ""),
    productCategories: getValidData(internalData?.metadata?.productCategories, ""),
    businessUnit: getValidData(internalData?.businessUnit, ""),
    factoryApprovalDate: internalData?.metadata?.factoryApprovalDate ? new Date(internalData.metadata.factoryApprovalDate).toISOString().split('T')[0] : "",
    tier: getValidData(internalData?.metadata?.tier, ""),
  };

  const [formData, setFormData] = useState(initData);

  // Store initial data to compare against
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    setInitialData(initData);
    setFormData(initData);
  }, [internalData]);

  const dispatch = useDispatch();

  // Add suppliers list from Redux store
  const suppliers = useSelector(selectSupplierList);

  // Fetch suppliers if not available
  useEffect(() => {
    if (!suppliers || suppliers.length === 0) {
      dispatch(getListOfAllSuppliers({
        limit: 999,
        offset: 0
      }));
    }
  }, [dispatch]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const changes = Object.keys(formData).reduce((acc, key) => {
      if (key === 'supplierIds') {
        // Handle supplier IDs update differently
        const [mainSupplierId, ...additionalSupplierIds] = formData.supplierIds;
        // Always include both supplierId and supplierIds in changes
        acc.supplierId = mainSupplierId;
        acc.supplierIds = additionalSupplierIds;
      } else if (formData[key] !== initialData[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});
    
    if (Object.keys(changes).length > 0) {
      // Use the first supplier ID from the list as the API route parameter
      const [routeSupplierId] = formData.supplierIds;
      dispatch(
        updateFactoryData({
          organisationId: internalData?.organisationId,
          supplierId: routeSupplierId, // Use the first supplier ID for the route
          factoryId: internalData?._id,
          changes: {
            ...changes,
            // Ensure both supplierId and supplierIds are included in changes
            supplierId: changes.supplierId,
            supplierIds: changes.supplierIds || [],
          },
        })
      );
    }
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: {
            xs: '95%',    // For extra-small screens
            sm: '95%',    // For small screens
            md: '90%',    // For medium screens
            lg: '1000px', // For large screens
          },
          maxWidth: '1000px',
          maxHeight: '90vh',
          overflowY: 'auto',
          p: {
            xs: 2,  // Less padding on small screens
            sm: 3,
            md: 4,  // More padding on larger screens
          },
          boxShadow:
            "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
          border: "1px solid rgba(234, 236, 240, 1)",
          borderRadius: "12px",
          py: 2,
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card
                sx={{
                  p: 4,
                  boxShadow:
                    "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
                  border: "1px solid rgba(234, 236, 240, 1)",
                  borderRadius: "12px",
                }}
              >
                <Grid
                  container
                  spacing={{ xs: 1, sm: 2 }}
                  justifyContent="space-between"
                  sx={{ mb: { xs: 1, sm: 2 } }}
                >
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      fullWidth
                      label="Factory ID"
                      name="factoryId"
                      variant="outlined"
                      value={formData.factoryId}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                      helperText={
                        <Typography
                          variant="body2"
                          sx={{ color: "#667085", fontSize: "14px" }}
                        >
                          Factory ID in your system
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      fullWidth
                      label="Agent"
                      name="agent"
                      variant="outlined"
                      value={formData.agent}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                      helperText={
                        <Typography
                          variant="body2"
                          sx={{ color: "#667085", fontSize: "14px" }}
                        >
                          Agent for this factory
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      fullWidth
                      label="Brand Type"
                      name="productCategories"
                      variant="outlined"
                      value={formData.productCategories}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                      helperText={
                        <Typography
                          variant="body2"
                          sx={{ color: "#667085", fontSize: "14px" }}
                        >
                          Brand type/Product categories
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={{ xs: 1, sm: 2 }} sx={{ mb: { xs: 1, sm: 2 } }}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Factory Address"
                      name="address"
                      variant="outlined"
                      value={formData.address}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={{ xs: 1, sm: 2 }} sx={{ mb: { xs: 1, sm: 2 } }}>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="supplier-select"
                      options={suppliers.map(supplier => ({
                        value: supplier.supplierId,
                        label: supplier.name
                      }))}
                      value={formData.supplierIds.map(id => {
                        const supplier = suppliers.find(s => s.supplierId === id);
                        return supplier ? {
                          value: supplier.supplierId,
                          label: supplier.name
                        } : null;
                      }).filter(Boolean)}
                      onChange={(event, newValue) => {
                        setFormData({
                          ...formData,
                          supplierIds: newValue.map(option => option.value),
                        });
                      }}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Suppliers"
                          margin="normal"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderRadius: "8px",
                              },
                            },
                          }}
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={option.value}
                            label={option.label}
                            {...getTagProps({ index })}
                            sx={{
                              backgroundColor: option.value === internalData?.supplierId ? "#4caf50" : undefined,
                              color: option.value === internalData?.supplierId ? "white" : undefined,
                            }}
                          />
                        ))
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={{ xs: 1, sm: 2 }} sx={{ mb: { xs: 1, sm: 2 } }}>
                  <Grid item xs={6}>
                    <TextField
                      select
                      fullWidth
                      label="Factory Status"
                      name="status"
                      variant="outlined"
                      value={formData.status}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    >
                      <MenuItem value="Approved">Approved</MenuItem>
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="Inactive">Inactive</MenuItem>
                      <MenuItem value="Not Approved">Not Approved</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      fullWidth
                      label="Tier"
                      name="tier"
                      variant="outlined"
                      value={formData.tier}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    >
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={{ xs: 1, sm: 2 }} sx={{ mb: { xs: 1, sm: 2 } }}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Next Audit Date"
                      name="nextAuditDate"
                      type="date"
                      variant="outlined"
                      value={formData.nextAuditDate}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          transform: "translate(14px, -6px) scale(0.75)",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Factory Approval Date"
                      name="factoryApprovalDate"
                      type="date"
                      variant="outlined"
                      value={formData.factoryApprovalDate}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          transform: "translate(14px, -6px) scale(0.75)",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={{ xs: 1, sm: 2 }} sx={{ mb: { xs: 1, sm: 2 } }}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Business Unit"
                      name="businessUnit"
                      variant="outlined"
                      value={formData.businessUnit}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                      helperText={
                        <Typography
                          variant="body2"
                          sx={{ color: "#667085", fontSize: "14px" }}
                        >
                          Business unit for this factory
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>

          </Grid>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              type="button"
              onClick={onClose}
              sx={{
                mr: 2,
                borderRadius: "8px",
                textTransform: "none",
                color: "#585aeb",
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#585aeb",
                borderRadius: "8px",
                textTransform: "none",
              }}
            >
              Save Changes
            </Button>
          </Box>
        </form>
      </Card>
    </Modal>
  );
};

export default FactoryInformationEditModal;
