import React, { useCallback, useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Chip,
  Box,
  TextField,
  Paper,
  Tooltip,
} from "@mui/material";
import CustomChipLabel from "./customChipLabel.component";
import { useDispatch, useSelector } from "react-redux";
import { selectAuditIssueDetails, selectSelectedAuditIssueId } from "../../selectors/audit.selector";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { debounce } from "lodash";
import { setSaveStatus } from "../../reducers/misc.reducer";
import { useParams } from "react-router-dom";
import { updateAuditData } from "../../reducers/audit.reducer";
import { selectSaveStatus } from "../../selectors/misc.selector";
import { getValidData } from "../../utils/lib";

const RecommendationsList = ({ title, items }) => (
  <Paper elevation={0} sx={{ mb: 2, p: 2 }}>
    <Typography
      sx={{
        color: "#475467",
        fontSize: "16px",
        fontWeight: 700,
      }}
    >
      {title}
    </Typography>
    <List dense>
      {items.map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemText
            primary={` ${item}`}
            sx={{
              color: "var(--text-primary, rgba(0, 0, 0, 0.90))",
              fontSize: "16px",
              fontWeight: 400,
            }}
          />
        </ListItem>
      ))}
    </List>
  </Paper>
);

const CopilotMatchCard = ({ match, isSelected, onSelect }) => {
  // Handle both old and new data structures
  const issueSummary = match.issueSummary || match.non_compliance?.issueSummary;
  const type = match.type || match.non_compliance?.type;
  const subType = match.subType || match.non_compliance?.subType;
  const issueDetail = match.issueDetail || match.non_compliance?.issueDetail;
  const confidenceScore = match.confidence_score || match.confidenceScore;

  return (
    <Paper 
      elevation={0} 
      sx={{
        mb: 2,
        p: 2,
        border: isSelected ? '2px solid #1976d2' : '1px solid #e0e0e0',
        cursor: 'pointer',
        '&:hover': {
          borderColor: '#1976d2',
          bgcolor: 'rgba(25, 118, 210, 0.04)'
        }
      }}
      onClick={onSelect}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600, mr: 1 }}>
            {issueSummary || 'Alternative Match'}
          </Typography>
          <Tooltip title="AI-suggested match">
            <AutoAwesomeIcon sx={{ fontSize: 16, color: '#1976d2' }} />
          </Tooltip>
        </Box>
        {isSelected && (
          <Chip
            label="Selected"
            color="primary"
            size="small"
            sx={{ ml: 1 }}
          />
        )}
      </Box>
      {(type || subType) && (
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          {[type, subType].filter(Boolean).join(' - ')}
        </Typography>
      )}
      {issueDetail && (
        <Typography variant="body1">
          {issueDetail}
        </Typography>
      )}
      {confidenceScore && (
        <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            Confidence: {(confidenceScore * 100).toFixed(1)}%
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

const AiGeneratedField = ({ label, children, copilotMatches, onMatchSelect, selectedMatchIndex }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        <Typography
          sx={{
            color: "#475467",
            fontSize: "16px",
            fontWeight: 700,
            display: "flex",
            alignItems: "center",
          }}
        >
          {label}
          {copilotMatches?.length > 1 && (
            <Tooltip title="Multiple AI suggestions available">
              <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                <AutoAwesomeIcon sx={{ fontSize: 16, color: "#1976d2" }} />
                <Typography variant="caption" sx={{ ml: 0.5, color: "#1976d2" }}>
                  {copilotMatches.length} matches
                </Typography>
              </Box>
            </Tooltip>
          )}
        </Typography>
        {copilotMatches?.length > 1 && (
          <Chip
            label={expanded ? "Hide Alternatives" : "Show Alternatives"}
            onClick={() => setExpanded(!expanded)}
            size="small"
            color="primary"
            variant={expanded ? "filled" : "outlined"}
            sx={{ ml: 2 }}
          />
        )}
      </Box>
      {children}
      {expanded && copilotMatches?.length > 1 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
            Alternative Matches:
          </Typography>
          {copilotMatches.map((match, index) => (
            index !== selectedMatchIndex && (
              <CopilotMatchCard
                key={index}
                match={match}
                isSelected={false}
                onSelect={() => onMatchSelect(index)}
              />
            )
          ))}
        </Box>
      )}
    </Box>
  );
};

const renderNonCompliance = (nc, index, data, selectedMatchIndexes, onMatchSelect) => {
  // Check for matches in both the root level and within capDetails
  const matches = nc.matches || (data.capDetails && data.capDetails[nc.id]?.matches) || [];
  
  if (matches.length > 0) {
    const selectedMatchIndex = selectedMatchIndexes[index] || 0;
    return (
      <div key={index} className="non-compliance-item copilot-enabled">
        <AiGeneratedField
          label="Non-Compliance Details"
          copilotMatches={matches}
          selectedMatchIndex={selectedMatchIndex}
          onMatchSelect={(matchIndex) => onMatchSelect(index, matchIndex)}
        >
          <CopilotMatchCard
            match={matches[selectedMatchIndex]}
            isSelected={true}
            onSelect={() => {}}
          />
        </AiGeneratedField>
      </div>
    );
  } else {
    // If no matches, display the original non-compliance description
    const description = nc.issueSummary || nc.non_compliance?.description;
    return (
      <div key={index} className="non-compliance-item">
        <Typography
          sx={{
            color: "#45464E",
            fontSize: "16px",
            fontWeight: 400,
          }}
        >
          {description}
        </Typography>
      </div>
    );
  }
};

const AuditReport = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const data = useSelector(selectAuditIssueDetails);
  const saveStatus = useSelector(selectSaveStatus);
  const selectedIssueId = useSelector(selectSelectedAuditIssueId);
  const [selectedMatchIndexes, setSelectedMatchIndexes] = useState({});

  const handleMatchSelect = useCallback((ncIndex, matchIndex) => {
    setSelectedMatchIndexes(prev => ({
      ...prev,
      [ncIndex]: matchIndex
    }));
    // TODO: Add logic to update the selected match in the backend
  }, []);

  const debouncedSaveNote = useCallback(
    debounce((newNote) => {
      dispatch(
        updateAuditData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { capDetailsNote: newNote },
          issueId: selectedIssueId,
        })
      );
    }, 1000),
    [dispatch, params, selectedIssueId]
  );

  useEffect(() => {
    if (saveStatus === "saved") {
      setTimeout(() => dispatch(setSaveStatus("")), 1000);
    }
  }, [saveStatus, dispatch]);

  const [note, setNote] = useState("");
  useEffect(() => {
    setNote("");
    setNote(getValidData(data.note));
  }, [selectedIssueId, data.note]);

  const handleNoteChange = (event) => {
    const newValue = event.target.value;
    dispatch(setSaveStatus("saving"));
    setNote(newValue);
    debouncedSaveNote(newValue);
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Box sx={{ p: 2 }}>
        {/* Header Section */}
        <Typography
          sx={{
            color: "#475467",
            fontSize: "30px",
            fontWeight: 500,
            textDecorationLine: "underline",
          }}
          gutterBottom
        >
          Issue
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontSize: "24px",
            fontWeight: 400,
            mt: 2,
            mb: 3,
          }}
        >
          {data.issueTitle}
        </Typography>

        {/* Details Section */}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={3}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
              }}
            >
              Category
            </Typography>
            <Typography
              sx={{
                color: "#45464E",
                fontSize: "16px",
                fontWeight: 400,
              }}
            >
              {data.type}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
              }}
            >
              Subcategory
            </Typography>
            <Typography
              sx={{
                color: "#45464E",
                fontSize: "16px",
                fontWeight: 400,
              }}
            >
              {data.subType}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ my: 2 }}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Issue Details
            </Typography>
            <Typography
              sx={{
                color: "#45464E",
                fontSize: "16px",
                fontWeight: 400,
              }}
            >
              {data.issueDetail}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Audit Code or Legal Reference
            </Typography>
            <Typography
              sx={{
                color: "#45464E",
                fontSize: "16px",
                fontWeight: 400,
              }}
            >
              {data.auditBasis}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Box>
              <Typography
                sx={{
                  color: "#475467",
                  fontSize: "16px",
                  fontWeight: 700,
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                StitchFix Code
              </Typography>
              
              <Box sx={{ 
                p: 2, 
                border: '1px solid #E0E7FF',
                borderRadius: 1,
                bgcolor: '#F5F8FF',
                mb: 2
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography
                    sx={{
                      color: "#1F2937",
                      fontSize: "15px",
                      fontWeight: 500,
                    }}
                  >
                    {data.clientCode}
                  </Typography>
                  <Tooltip title="AI-selected match">
                    <AutoAwesomeIcon sx={{ fontSize: 16, color: '#1976d2' }} />
                  </Tooltip>
                </Box>
              </Box>

              {data.matches?.filter(match => match !== data.clientCode)?.length > 0 && (
                <Box>
                  <Typography 
                    sx={{ 
                      color: 'text.secondary',
                      fontSize: '14px',
                      fontWeight: 500,
                      mb: 1.5,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}
                  >
                    Other potential matches
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    {data.matches.filter(match => match !== data.clientCode).map((match, index) => (
                      <Typography
                        key={index}
                        sx={{
                          color: "#6B7280",
                          fontSize: "14px",
                          mb: 1,
                        }}
                      >
                        {match}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={3}>
            <AiGeneratedField label="Severity">
              <Typography variant="body1" sx={{ color: "error.main" }}>
                <CustomChipLabel severity={data.severity} />
              </Typography>
            </AiGeneratedField>
          </Grid>

          {/* Rating Explanation */}
          <Grid item xs={7}>
            <AiGeneratedField label="Rating Explanation">
              <Typography
                sx={{
                  color: "#45464E",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                {data.ratingExplanation}
              </Typography>
            </AiGeneratedField>
          </Grid>

          {/* Internal Note */}
          <Grid item xs={12}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Internal Note
            </Typography>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              value={note}
              onChange={handleNoteChange}
              helperText={
                saveStatus === "saving" ? (
                  <Typography
                    sx={{
                      color: "error.main",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Saving...
                  </Typography>
                ) : saveStatus === "saved" ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        color: "success.main",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      Saved
                    </Typography>
                  </Box>
                ) : (
                  ""
                )
              }
              inputProps={{
                style: {
                  WebkitTextFillColor: "#45464E",
                  color: "#45464E",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* CAP Recommendation Section */}
          <Grid item xs={6}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "30px",
                fontWeight: 500,
                textDecorationLine: "underline",
                mb: 2, // Added margin bottom for spacing
              }}
            >
              CAP Recommendations
            </Typography>
            <AiGeneratedField label="Immediate Corrective Action Plan">
              <RecommendationsList
                title=""
                items={
                  data?.capRecommendations?.immediateCAP
                    ? String(data.capRecommendations.immediateCAP).split('\n')
                    : []
                }
              />
            </AiGeneratedField>
            <AiGeneratedField label="Long Term Preventative Action">
              <RecommendationsList
                title=""
                items={
                  data?.capRecommendations?.longTermPreventativeAction
                    ? String(data.capRecommendations.longTermPreventativeAction).split('\n')
                    : []
                }
              />
            </AiGeneratedField>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "30px",
                fontWeight: 500,
                textDecorationLine: "underline",
              }}
            >
              Factory Comment
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={8} sx={{ my: 3 }}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "16px",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  Accountable Person
                </Typography>
                <Typography
                  sx={{
                    color: "#45464E",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                  {data.responsiblePerson}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ my: 3 }}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "16px",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  Target Deadline
                </Typography>
                <Typography
                  sx={{
                    color: "#45464E",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                  -
                </Typography>
              </Grid>
            </Grid>

            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
                mb: 1,
              }}
            >
              Immediate Corrective Action Plan
            </Typography>
            <Typography
              sx={{
                color: "#45464E",
                fontSize: "16px",
                fontWeight: 400,
                fontStyle: "italic",
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              {data.immediateCorrectiveActionPlan || "To be filled by factory"}
            </Typography>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
                mb: 1,
                mt: 2,
              }}
            >
              Long Term Preventative Action Plan
            </Typography>
            <Typography
              sx={{
                color: "#45464E",
                fontSize: "16px",
                fontWeight: 400,
                fontStyle: "italic",
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              {data.longTermPreventativeActionPlan || "To be filled by factory"}
            </Typography>

            <Typography
              sx={{
                color: "#475467",
                fontSize: "30px",
                fontWeight: 500,
                textDecorationLine: "underline",
                my: 3,
              }}
            >
              CAP Progress
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "16px",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  Status
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CustomChipLabel
                    cap={data.statusChipLabel}
                    capLabel={data.status}
                  />
                  <Box sx={{ minWidth: "8px" }}></Box>
                  {data.timeline && (
                    <CustomChipLabel cap={"default"} capLabel={data.timeline} />
                  )}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "16px",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  Completion Date
                </Typography>
                <Typography
                  sx={{
                    color: "#45464E",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                  {data.completionDate || "-"}
                </Typography>
              </Grid>
            </Grid>

            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                fontWeight: 700,
                mt: 3,
              }}
            >
              Verification Note
            </Typography>
            <Typography
              sx={{
                color: "#45464E",
                fontSize: "16px",
                fontWeight: 400,
                mb: 3,
              }}
            >
              {data.verificationNote || "-"}
            </Typography>
          </Grid>
        </Grid>
        {data.nonCompliances && data.nonCompliances.length > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: "#475467",
                  fontSize: "30px",
                  fontWeight: 500,
                  textDecorationLine: "underline",
                  mb: 2,
                }}
              >
                Non-Compliances
              </Typography>
              {data.nonCompliances.map((nc, index) => renderNonCompliance(nc, index, data, selectedMatchIndexes, handleMatchSelect))}
            </Grid>
          </Grid>
        )}
        <Typography
          sx={{
            color: "#000",
            textAlign: "right",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
          }}
        >
          Last Updated by AuditSense AI 
        </Typography>
      </Box>
    </Container>
  );
};

export default AuditReport;
