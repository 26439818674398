import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Drawer,
  Box,
  Divider,
  CircularProgress
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { selectFactoryData } from "../../selectors/factory.selector";
import { selectSupplierNameFromId } from "../../selectors/supplier.selector";
import { selectEditModeState } from "../../selectors/misc.selector";
import { selectSupplierList } from "../../selectors/supplier.selector";
import { getListOfAllSuppliers } from "../../reducers/supplier.reducer";
import FactoryInformationEditModal from "./EditModals/editFactoryInformation.component";
import FacilityTierMap from "./facilityTierMap.component";
import { getFactoryById } from "../../reducers/factory.reducer";

const capitalizeWords = (str) => {
  if (!str) return '';
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const FacilityInformation = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const selectedFactoryData = useSelector(selectFactoryData);
  const [factoryData, setFactoryData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditingInternal, setIsEditingInternal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoadingSuppliers, setIsLoadingSuppliers] = useState(true);

  const editMode = useSelector(selectEditModeState);
  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, params.supplierId)
  );
  const suppliers = useSelector(selectSupplierList);

  useEffect(() => {
    // Fetch suppliers if not already loaded
    if (!suppliers || suppliers.length === 0) {
      dispatch(getListOfAllSuppliers({
        limit: 999,
        offset: 0
      }));
    } else {
      setIsLoadingSuppliers(false);
    }
  }, [dispatch, suppliers]);

  useEffect(() => {
    const loadFactoryData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        if (!selectedFactoryData) {
          await dispatch(getFactoryById({ supplierId: params.supplierId, factoryId: params.factoryId }));
        } else {
          setFactoryData(selectedFactoryData);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    loadFactoryData();
  }, [selectedFactoryData, params.supplierId, params.factoryId, dispatch]);

  useEffect(() => {
    if (selectedFactoryData) {
      setFactoryData(selectedFactoryData);
    }
  }, [selectedFactoryData]);

  const toggleEditInternal = () => {
    setIsEditingInternal(!isEditingInternal);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const renderSupplierLinks = () => {
    if (!factoryData?.suppliers || !factoryData?.supplierNames) return null;
    if (isLoadingSuppliers) {
      return <CircularProgress size={20} />;
    }
    
    return (
      <Box component="ul" sx={{ padding: 0, margin: 0, listStyle: "none" }}>
        {factoryData.suppliers.map((id, index) => {
          const name = factoryData.supplierNames[index];
          if (!name) return null;
          
          return (
            <Box component="li" key={id} sx={{ marginBottom: "1px" }}>
              <Link
                to={`/suppliers/${id}`}
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  color: "#3538CD",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                {name}
                <img style={{ paddingLeft: 8 }} src="/ne_arrow.svg" alt="arrow icon" />
              </Link>
            </Box>
          );
        })}
      </Box>
    );
  };

  const drawerContent = factoryData && (
    <Box
      sx={{ width: 787.5, padding: 4, paddingLeft: 6, paddingRight: 6 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Typography variant="h6" sx={{ mb: 3, textDecoration: 'underline' }}>
        Factory General Information
      </Typography>
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={6}>
          <Typography>
            <span style={{ fontWeight: 500, fontSize: 16, color: "#8B8D97" }}>
              Vendors:
            </span>{" "}
            {renderSupplierLinks()}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#8B8D97" }}>
            Factory Address
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#45464E" }}>
            {capitalizeWords(factoryData?.metadata?.address)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#8B8D97" }}>
            Country
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#45464E" }}>
            {factoryData?.metadata?.country}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#8B8D97" }}>
            Factory ID
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#45464E" }}>
            {factoryData?.metadata?.factoryId}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#8B8D97" }}>
            Tier
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#45464E" }}>
            {factoryData?.metadata?.tier}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#8B8D97" }}>
            Product Categories
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#45464E" }}>
            {factoryData?.metadata?.productCategories}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#8B8D97" }}>
            Worker Count
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#45464E" }}>
            {factoryData?.metadata?.workerCount}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#8B8D97" }}>
            Status
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#45464E" }}>
            {factoryData?.metadata?.status}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#8B8D97" }}>
            Alias
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#45464E" }}>
            {factoryData?.metadata?.alias}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#8B8D97" }}>
            Agent
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#45464E" }}>
            {factoryData?.metadata?.agent || '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#8B8D97" }}>
            High Strike Risk
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#45464E" }}>
            {factoryData?.metadata?.highStrikeRisk || '-'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#8B8D97" }}>
            Next Report Date
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#45464E" }}>
            {factoryData?.metadata?.nextReportDate}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#8B8D97" }}>
            Audit Type
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#45464E" }}>
            {factoryData?.metadata?.auditType}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#8B8D97" }}>
            Factory Approval Date
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#45464E" }}>
            {factoryData?.metadata?.factoryApprovalDate}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 3, mb: 3 }} />
      <Typography variant="h6" sx={{ mb: 3, textDecoration: 'underline' }}>
        Facility Tier Map
      </Typography>
      <FacilityTierMap supplierId={factoryData.supplierId} currentFactoryId={params.factoryId} />
    </Box>
  );

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!factoryData) {
    return <div>No factory data available.</div>;
  }

  return (
    <>
      <Card
        style={{
          padding: "0px 32px",
          boxShadow:
            "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
          border: "1px solid rgba(234, 236, 240, 1)",
          borderRadius: "12px",
        }}
      >
        <CardContent>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
            <Grid item>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: 16,
                  color: "#344054",
                  display: "inline-block",
                  textDecoration: 'underline'
                }}
              >
                Factory General Information
              </Typography>
              {editMode && (
                <EditIcon
                  fontSize={"2"}
                  sx={{ ml: 1, cursor: "pointer", color: "#585aeb" }}
                  onClick={toggleEditInternal}
                />
              )}
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={toggleDrawer(true)}
                sx={{
                  backgroundColor: "transparent",
                  color: "#6172F3",
                  borderColor: "#6172F3",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                    borderColor: "#5667e2",
                  },
                  textTransform: "none",
                  width: "150px",
                  height: "40px",
                  borderRadius: "8px",
                }}
              >
                More Information
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
            <Grid item>
              <Typography>
                <span style={{ fontWeight: 500, fontSize: 16, color: "#8B8D97" }}>
                  Vendors:
                </span>{" "}
                {renderSupplierLinks()}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <span style={{ fontWeight: 500, fontSize: 16, color: "#8B8D97" }}>
                  Status:
                </span>{" "}
                {factoryData?.metadata?.status}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#8B8D97" }}>
                Factory Address
              </Typography>
              <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#45464E" }}>
                {capitalizeWords(factoryData?.metadata?.address)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        {drawerContent}
      </Drawer>
      <FactoryInformationEditModal
        open={isEditingInternal}
        onClose={() => setIsEditingInternal(false)}
        internalData={{ ...factoryData, supplierName }}
      />
    </>
  );
};

export default FacilityInformation;
