import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectFactoryList } from "../../../selectors/factory.selector";
import {
  getFactoryById,
  getListOfAllFactories,
} from "../../../reducers/factory.reducer";
import { ScatterChart } from "@mui/x-charts/ScatterChart";

const RiskScatterPlot = () => {
  const dispatch = useDispatch();
  const facilityList = useSelector(selectFactoryList);

  useEffect(() => {
    dispatch(getListOfAllFactories({}));
  }, []); 

  const [selectedFacilityId, setSelectedFacilityId] = useState("");
  const [selectedSupplierId, setSeletectedSupplierId] = useState("");
  const [riskType, setRiskType] = useState("riskScore");

  const tempSpendPlaceHolder = 1.5;


  const data = facilityList.map((facility) => ({
    id: facility?.factoryId,
    x: riskType === "riskScore" ? facility?.riskScore : facility?.enhancedRisk,
    y: Number(((facility?.influence || tempSpendPlaceHolder))),
    supplierId: facility?.supplierId,
    facilityName: facility?.name,
  }));

  const filteredData = data.filter((item) => item.x !== 0);

  const handleClick = (event, itemData) => {
    event.preventDefault();
    const facilityId = filteredData[itemData.dataIndex].id;
    const supplierId = filteredData[itemData.dataIndex].supplierId;
    setSelectedFacilityId(facilityId);
    setSeletectedSupplierId(supplierId);
  };

  const handleRiskTypeChange = (event) => {
    setRiskType(event.target.value);
  };

  useEffect(() => {
    dispatch(
      getFactoryById({
        supplierId: selectedSupplierId,
        factoryId: selectedFacilityId,
      })
    );
  }, [selectedSupplierId, selectedFacilityId]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        maxHeight: "60vh",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: "#182230",
          fontWeight: 500,
          fontSize: "36px",
          mb: 2,
        }}
      >
        Factory Risk Map
      </Typography>
      
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} >
          <Typography
            variant="caption"
            sx={{ color: "#667085", fontStyle: "italic", mb: 1, display: "block" }}
          >
            Influence data is set to 1.5 for all factories. And data discrepancy might happen during data migration.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} >
          <FormControl sx={{ mb: 2, width: "100%", pr: 6 }}>
            <InputLabel id="risk-type-select-label">Risk Type</InputLabel>
            <Select
              labelId="risk-type-select-label"
              id="risk-type-select"
              value={riskType}
              label="Risk Type"
              onChange={handleRiskTypeChange}
            >
              <MenuItem value="riskScore">Risk Score</MenuItem>

            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{ flex: 1, maxheight: "60vh", mb: 2 }}>
        <ScatterChart
          series={[
            {
              id: "facility",
              data: filteredData.map((facility) => ({
                x: facility.x,
                y: facility.y,
                id: facility.id,
                facilityName: facility.facilityName,
              })),
              valueFormatter: (item) => {
                return `${item.facilityName} | ${riskType === "riskScore" ? "Risk Score" : ""}: ${item.x} | Influence: ${item.y}`;
              },
              highlightScope: {
                highlighted: "item",
                faded: "global",
              },
              color: "#3538CD", 
            },
          ]}
          grid={{ vertical: true, horizontal: true }}
          xAxis={[
            {
              label: riskType === "riskScore" ? "Risk Score" : "",
              labelStyle: { fontSize: 12 },
              min: 0,
              max: 3,
            },
          ]}
          yAxis={[
            {
              label: "Influence",
              labelStyle: { fontSize: 12 },
              min: 0,
              max: 3,
            },
          ]}
          sx={{
            ".MuiChartsAxis-left .MuiChartsAxis-line": {
              stroke: "rgba(0, 0, 0, 0.2)",
            },
            ".MuiChartsAxis-left .MuiChartsAxis-tick": {
              stroke: "rgba(0, 0, 0, 0.2)",
            },
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          onItemClick={(event, itemData) => handleClick(event, itemData)}
        />
      </Box>
    </Box>
  );
};

export default RiskScatterPlot;
