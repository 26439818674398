import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Card,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { getValidData } from "../../../utils/lib";
import { updateSupplierData } from "../../../reducers/supplier.reducer";

import CommentSection from "../../commentSection.component";

const SupplierInformationEditModal = ({
  open = false,
  onClose,
  supplierData,
}) => {
  const initData = {
    name: getValidData(supplierData?.name),
    alias: getValidData(supplierData?.metadata?.alias),
    contactNote: getValidData(
      supplierData?.metadata?.contactPerson?.contactNote
    ),
    status: getValidData(supplierData?.metadata?.status) || getValidData(supplierData?.metadata?.vendorStatus) || "Approved",
  };

  const [formData, setFormData] = useState(initData);

  // Store initial data to compare against
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    setInitialData(initData);
    setFormData(initData);
  }, [supplierData]);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const changes = {};

    // Handle root level fields (name)
    if (formData.name !== initialData.name) {
      changes.name = formData.name;
    }

    // Handle metadata fields directly
    if (formData.alias !== initialData.alias) {
      changes.alias = formData.alias;
    }
    if (formData.status !== initialData.status) {
      changes.status = formData.status;
    }
    if (formData.contactNote !== initialData.contactNote) {
      changes.contactNote = formData.contactNote;
    }

    if (Object.keys(changes).length > 0) {
      dispatch(
        updateSupplierData({
          supplierId: supplierData?._id,
          changes,
        })
      );
    }
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: "1200px",
          maxHeight: "90vh",
          overflow: "auto",
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
          Edit Supplier Information
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                sx={{ mb: 1 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                multiline
                rows={8}
                label="Contact Information"
                name="contactNote"
                value={formData.contactNote}
                onChange={handleChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: '100%',
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                multiline
                rows={8}
                label="Vendor Alias"
                name="alias"
                value={formData.alias}
                onChange={handleChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: '100%',
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="vendor-status-label">Vendor Status</InputLabel>
                <Select
                  labelId="vendor-status-label"
                  id="vendor-status"
                  name="status"
                  value={formData.status}
                  label="Vendor Status"
                  onChange={handleChange}
                  sx={{
                    borderRadius: "8px",
                  }}
                >
                  <MenuItem value="Approved">Approved</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
            <Button
              type="button"
              onClick={onClose}
              sx={{
                mr: 2,
                borderRadius: "8px",
                textTransform: "none",
                color: "#585aeb",
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#585aeb",
                borderRadius: "8px",
                textTransform: "none",
              }}
            >
              Save Changes
            </Button>
          </Box>
        </form>
      </Card>
    </Modal>
  );
};

export default SupplierInformationEditModal;
