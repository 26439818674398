import { all, put, select, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
    sendEmailToMentioned,
    sendAuditEmail,
    sendSupplierReminder,
    sendEmailSuccess,
    sendEmailFailure
} from "../reducers/email.reducer";
import { selectAuthToken } from "../selectors/login.selector";
import * as API from "../utils/api";
import { logout } from "../reducers/login.reducer";

function* sendEmailToMentionedWorker(action) {
    const authToken = yield select(selectAuthToken);

    const requestURL = API.EMAIL.SEND_TO_MENTIONED;
    console.log(`requestURL: ${requestURL}`);

    const headers = {
        "Content-Type": "application/json",
        Authorization: authToken,
    };

    const body = {
        organisationId: action.payload.organisationId,
        supplierId: action.payload.supplierId,
        factoryId: action.payload.factoryId,
        supplierName: action.payload.supplierName,
        factoryName: action.payload.factoryName,
        recipientEmails: action.payload.mentionedEmails,
        sender: action.payload.commentAuthor,
        comment: action.payload.comment
    };

    try {
        const response = yield axios.post(requestURL, body, { headers });
        if (response?.status === 200 && response.data?.success) {
            yield put(sendEmailSuccess());
        }
    } catch (error) {
        console.error("Failed to send emails:", error);
        yield put(sendEmailFailure(error.message));
        if (error?.response?.status === 401) {
            yield put(logout());
        }
    }
}

function* sendAuditEmailWorker(action) {
    const authToken = yield select(selectAuthToken);

    const requestURL = API.EMAIL.SEND_AUDIT_NOTIFICATION;

    const headers = {
        "Content-Type": "application/json",
        Authorization: authToken,
    };

    const body = {
        organisationId: action.payload.organisationId,
        supplierId: action.payload.supplierId,
        factoryId: action.payload.factoryId,
        auditId: action.payload.auditId,
        supplierName: action.payload.supplierName,
        factoryName: action.payload.factoryName,
        auditName: action.payload.auditName,
        recipientEmails: action.payload.recipientEmails,
        sender: action.payload.sender,
        comment: action.payload.comment
    };

    try {
        const response = yield axios.post(requestURL, body, { headers });
        if (response?.status === 200 && response.data?.success) {
            yield put(sendEmailSuccess());
        }
    } catch (error) {
        console.error("Failed to send emails:", error);
        yield put(sendEmailFailure(error.message));
        if (error?.response?.status === 401) {
            yield put(logout());
        }
    }
}

function* sendSupplierReminderWorker(action) {
    const authToken = yield select(selectAuthToken);
    const requestURL = API.EMAIL.SEND_SUPPLIER_REMINDER;

    const headers = {
        "Content-Type": "application/json",
        Authorization: authToken,
    };

    const body = {
        organisationId: action.payload.organisationId,
        email: action.payload.email,
        ...(action.payload.supplierId && { supplierId: action.payload.supplierId })
    };

    try {
        const response = yield axios.post(requestURL, body, { headers });
        if (response?.status === 200 && response.data?.success) {
            yield put(sendEmailSuccess());
        }
    } catch (error) {
        console.error("Failed to send supplier reminder:", error);
        yield put(sendEmailFailure(error.message));
        if (error?.response?.status === 401) {
            yield put(logout());
        }
    }
}

function* watchSendAuditEmailSaga() {
    yield takeLatest(sendAuditEmail.type, sendAuditEmailWorker);
}

function* watchSendEmailToMentionedSaga() {
    yield takeLatest(sendEmailToMentioned.type, sendEmailToMentionedWorker);
}

function* watchSendSupplierReminderSaga() {
    yield takeLatest(sendSupplierReminder.type, sendSupplierReminderWorker);
}

export default function* rootSaga() {
    yield all([
        watchSendEmailToMentionedSaga(),
        watchSendAuditEmailSaga(),
        watchSendSupplierReminderSaga()
    ]);
}