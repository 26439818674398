import React, { useEffect } from "react";
import { Grid, Box, Divider, Typography } from "@mui/material";

import LeftSideNavbar from "../components/leftSideNavbar.component";
import TopBreadcrumbs from "../components/topBreadcrumbs.component";

import { useDispatch, useSelector } from "react-redux";

import { selectOrganisationId } from "../selectors/login.selector";

import { PageStyles } from "../styles";

import { selectDueActionTabs } from "../selectors/dueAction.selector";
import { getDueAudits, getDueCaps } from "../reducers/dueAction.reducer";
import DueActionTabs from "../components/DueActionDetails/DueActionTabs.component";
import DueCapsDataGrid from "../components/DueActionDetails/DueCapsDataGrid.component";
import DueAuditsDataGrid from "../components/DueActionDetails/DueAuditsDataGrid.component";

const DueAction = () => {
  const dispatch = useDispatch();
  const organisationId = useSelector(selectOrganisationId);
  const selectedTab = useSelector(selectDueActionTabs);

  useEffect(() => {
    if (selectedTab === "cap") {
      dispatch(getDueCaps({ organisationId }));
    } else {
      dispatch(getDueAudits({ organisationId }));
    }
  }, [selectedTab, organisationId]);

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSideNavbar />
      <Box sx={{ flex: 1, overflow: "auto" }}>
        <Grid container direction="column">
          <Box sx={PageStyles.containerBoxExceptNavbar}>
            <Box sx={PageStyles.firstSectionWithBreadcrumbsAndName}>
              <TopBreadcrumbs />
              <Typography
                variant="h6"
                style={{ fontSize: 20, fontWeight: 600 }}
              >
                Due Action Center
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}
              >
                Manage all the due actions
              </Typography>
            </Box>
            <Divider />
          </Box>

          <Divider />
        </Grid>
        <Box
          sx={{
            flex: 1,
          }}
        >
          <DueActionTabs />
          {selectedTab === "cap" ? <DueCapsDataGrid /> : <DueAuditsDataGrid />}
        </Box>
      </Box>
    </Box>
  );
};

export default DueAction;
