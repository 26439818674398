export const containerBox = { display: "flex", height: "100vh" };
export const containerBoxExceptNavbar = { flex: 1, overflow: "auto" };
export const containerBoxWithNavbar = {
  flex: 1,
  margin: "0 20px",
  padding: "12px 22px",
};

export const name = {
  pt: 1,
  pb: 0,
  fontWeight: 600,
  fontSize: 30,
  display: "flex",
  alignItems: "center",
};

export const secondSectionGrid = {
  p: "0px 24px 24px 24px",
  backgroundColor: "var(--Gray-50, #F9FAFB)",
};

export const tierAndPrivateLabel = {
  textTransform: "none",
  ml: 2,
  p: "3px 8px",
  borderRadius: "6px",
  border: "1px solid var(--Brand-300, #D0D5DD)",
  background: "#FFF",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  color: "#344054",
  fontSize: "12px",
  fontWeight: 500,
};

export const auditContainerCard = {
  boxShadow:
    "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",

  border: "1px solid rgba(234, 236, 240, 1)",
  borderRadius: "12px",
  py: 2,
};

export const firstSectionWithBreadcrumbsAndName = {
  flex: 1,
  margin: "0 20px",
  padding: "12px 22px",
};

export const cardForTabsAndBelow = {
  boxShadow:
    "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",

  border: "1px solid rgba(234, 236, 240, 1)",
  borderRadius: "12px",
  px: 5,
  pb: 4,
};
