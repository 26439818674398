import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { createAuditCapDetail } from "../../reducers/audit.reducer";
import { useParams } from "react-router-dom";

const CreateFindingModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const params = useParams();
  
  // Get params from URL
  const { organisationId, supplierId, factoryId, auditId } = params;
  
  // Initialize state for the form fields
  const [formData, setFormData] = useState({
    issueSummary: "",
    type: "",
    subType: "",
    severity: "",
    issueDetail: "",
    // Optional fields
    clientCodeTitle: "",
    referenceId: "",
    rootCause: "",
    immediateCorrectiveAction: "",
    longTermPreventativeAction: "",
    targetDeadline: "",
    responsiblePerson: "",
  });
  
  // Initialize error state
  const [errors, setErrors] = useState({});
  
  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    
    // Clear error when field is updated
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };
  
  // Validate the form
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["issueSummary", "type", "subType", "severity", "issueDetail"];
    
    requiredFields.forEach(field => {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
      }
    });
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  // Submit the form
  const handleSubmit = () => {
    if (validateForm()) {
      // Format data according to backend expectations
      const formattedData = {
        issueSummary: formData.issueSummary,
        type: formData.type,
        subType: formData.subType,
        severity: formData.severity,
        issueDetail: formData.issueDetail,
        // Optional fields with defaults
        referenceId: formData.referenceId || `MANUAL-${new Date().getTime()}`,
        rootCause: formData.rootCause || "",
        // Format recommendations as expected by backend
        immediateCorrectiveAction: formData.immediateCorrectiveAction || "",
        longTermPreventativeAction: formData.longTermPreventativeAction || "",
        targetDeadline: formData.targetDeadline || "",
        responsiblePerson: formData.responsiblePerson || "",
        status: "Open", // Default status for new findings
      };
      
      console.log("Submitting finding:", formattedData);
      
      dispatch(
        createAuditCapDetail({
          organisationId,
          supplierId,
          factoryId,
          auditId,
          capDetailData: formattedData,
        })
      );
      onClose();
      // Reset form
      setFormData({
        issueSummary: "",
        type: "",
        subType: "",
        severity: "",
        issueDetail: "",
        clientCodeTitle: "",
        referenceId: "",
        rootCause: "",
        immediateCorrectiveAction: "",
        longTermPreventativeAction: "",
        targetDeadline: "",
        responsiblePerson: "",
      });
    }
  };
  
  // Types for dropdown
  const types = [
    "Labour",
    "Health & Safety",
    "Environment",
    "Business Ethics",
    "Management Systems",
    "Other",
  ];
  
  // Subtypes mapping
  const subTypeMapping = {
    "Labour": [
      "Child Labour",
      "Forced Labour",
      "Discrimination",
      "Freedom of Association",
      "Working Hours",
      "Wages and Benefits",
      "Regular Employment",
      "Harassment and Abuse",
    ],
    "Health & Safety": [
      "Building Safety",
      "Fire Safety",
      "Machine Safety",
      "Chemical Safety",
      "Personal Protective Equipment",
      "Working Conditions",
      "First Aid",
      "Emergency Preparedness",
    ],
    "Environment": [
      "Waste Management",
      "Water Management",
      "Energy Management",
      "Air Emissions",
      "Noise Pollution",
      "Chemical Management",
    ],
    "Business Ethics": [
      "Corruption",
      "Fair Competition",
      "Intellectual Property",
      "Data Protection",
      "Responsible Marketing",
    ],
    "Management Systems": [
      "Documentation",
      "Training",
      "Monitoring",
      "Corrective Action",
      "Supplier Management",
    ],
    "Other": ["Miscellaneous"],
  };
  
  // Severity options
  const severityOptions = [
    "ZT", // Zero Tolerance
    "HR", // High Risk
    "Major",
    "Minor",
  ];
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
          Create New Finding
        </Typography>
      </DialogTitle>
      
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={3}>
            {/* Issue Summary */}
            <Grid item xs={12}>
              <TextField
                name="issueSummary"
                label="Issue Summary*"
                fullWidth
                value={formData.issueSummary}
                onChange={handleChange}
                error={!!errors.issueSummary}
                helperText={errors.issueSummary}
              />
            </Grid>
            
            {/* Type */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.type}>
                <InputLabel>Type*</InputLabel>
                <Select
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  label="Type*"
                >
                  {types.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
                {errors.type && <FormHelperText>{errors.type}</FormHelperText>}
              </FormControl>
            </Grid>
            
            {/* Sub Type */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.subType} disabled={!formData.type}>
                <InputLabel>Sub Type*</InputLabel>
                <Select
                  name="subType"
                  value={formData.subType}
                  onChange={handleChange}
                  label="Sub Type*"
                >
                  {formData.type &&
                    subTypeMapping[formData.type]?.map((subType) => (
                      <MenuItem key={subType} value={subType}>
                        {subType}
                      </MenuItem>
                    ))}
                </Select>
                {errors.subType && <FormHelperText>{errors.subType}</FormHelperText>}
              </FormControl>
            </Grid>
            
            {/* Severity */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.severity}>
                <InputLabel>Severity*</InputLabel>
                <Select
                  name="severity"
                  value={formData.severity}
                  onChange={handleChange}
                  label="Severity*"
                >
                  {severityOptions.map((severity) => (
                    <MenuItem key={severity} value={severity}>
                      {severity}
                    </MenuItem>
                  ))}
                </Select>
                {errors.severity && <FormHelperText>{errors.severity}</FormHelperText>}
              </FormControl>
            </Grid>
            
            {/* Reference ID (optional) */}
            <Grid item xs={12} sm={6}>
              <TextField
                name="referenceId"
                label="Reference ID"
                fullWidth
                value={formData.referenceId}
                onChange={handleChange}
              />
            </Grid>
            
            {/* Issue Detail */}
            <Grid item xs={12}>
              <TextField
                name="issueDetail"
                label="Issue Detail*"
                fullWidth
                multiline
                rows={4}
                value={formData.issueDetail}
                onChange={handleChange}
                error={!!errors.issueDetail}
                helperText={errors.issueDetail}
              />
            </Grid>
            
            {/* Root Cause (optional) */}
            <Grid item xs={12}>
              <TextField
                name="rootCause"
                label="Root Cause"
                fullWidth
                multiline
                rows={2}
                value={formData.rootCause}
                onChange={handleChange}
              />
            </Grid>
            
            {/* Immediate Corrective Action (optional) */}
            <Grid item xs={12} sm={6}>
              <TextField
                name="immediateCorrectiveAction"
                label="Immediate Corrective Action"
                fullWidth
                multiline
                rows={2}
                value={formData.immediateCorrectiveAction}
                onChange={handleChange}
              />
            </Grid>
            
            {/* Long Term Preventative Action (optional) */}
            <Grid item xs={12} sm={6}>
              <TextField
                name="longTermPreventativeAction"
                label="Long Term Preventative Action"
                fullWidth
                multiline
                rows={2}
                value={formData.longTermPreventativeAction}
                onChange={handleChange}
              />
            </Grid>
            
            {/* Target Deadline (optional) */}
            <Grid item xs={12} sm={6}>
              <TextField
                name="targetDeadline"
                label="Target Deadline"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={formData.targetDeadline}
                onChange={handleChange}
              />
            </Grid>
            
            {/* Responsible Person (optional) */}
            <Grid item xs={12} sm={6}>
              <TextField
                name="responsiblePerson"
                label="Responsible Person"
                fullWidth
                value={formData.responsiblePerson}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      
      <DialogActions sx={{ p: 3 }}>
        <Button 
          onClick={onClose} 
          variant="outlined" 
          color="primary"
          sx={{ mr: 1 }}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Create Finding
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateFindingModal;
