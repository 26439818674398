import { all, put, select, takeLatest } from "redux-saga/effects";

import {
  getListOfAllAudits,
  getAuditById,
  setAuditData,
  setListOfAllAudits,
  updateAuditData,
  setAuditIssueCAPData,
  uploadAuditReport,
  setUploadAuditReportFiles,
  processAuditReport,
  setProcessAuditReportFiles,
  regenerateCap,
  setRegenerateCapResult,
  deleteCapDetailsById,
  createAuditCapDetail,
  setCreateCapDetailResult,
} from "../reducers/audit.reducer";
import { logout } from "../reducers/login.reducer";
import { selectAuthToken, selectOrganisationId } from "../selectors/login.selector";
import { AUDITS } from "../utils/api";
import axios from "axios";
import { selectSaveStatus } from "../selectors/misc.selector";
import { setFilesUploadedIds, setSaveStatus } from "../reducers/misc.reducer";

function* getListOfAuditsWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);
  const { limit, offset, supplierId } = action.payload;
  const requestURL = AUDITS.LIST.replace(
    "<ORGANISATION_ID>",
    organisationId
  ).replace("<SUPPLIER_ID>", supplierId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const params = { limit, offset };

  try {
    const response = yield axios.get(requestURL, { headers, params });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(
          setListOfAllAudits({
            audits: response.data.results.audits,
            auditCount: response.data.results.auditCount,
          })
        );
      }
    }
  } catch (error) {
    console.error("Failed to fetch suppliers:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* getAuditByIdWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = AUDITS.GET_BY_ID.replace(
    "<ORGANISATION_ID>",
    organisationId
  )
    .replace("<SUPPLIER_ID>", action.payload.supplierId)
    .replace("<FACTORY_ID>", action.payload.factoryId)
    .replace("<AUDIT_ID>", action.payload.auditId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  try {
    const response = yield axios.get(requestURL, { headers });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setAuditData(response.data.results.auditData));
        yield put(setAuditIssueCAPData(response.data.results.capDetails));
      }
    }
  } catch (error) {
    console.error("Failed to fetch suppliers:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* updateAuditDataWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = AUDITS.UPDATE_BY_ID.replace(
    "<ORGANISATION_ID>",
    organisationId
  )
    .replace("<SUPPLIER_ID>", action.payload.supplierId)
    .replace("<FACTORY_ID>", action.payload.factoryId)
    .replace("<AUDIT_ID>", action.payload.auditId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const body = {
    ...action.payload.changes,
  };
  const params = {
    issueId: action.payload?.issueId || "",
  };
  try {
    const response = yield axios.put(requestURL, body, { headers, params });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setAuditData(response.data.results.auditData));
        yield put(setAuditIssueCAPData(response.data.results.capDetails));

        yield put(setSaveStatus("saved"));
      }
    }
  } catch (error) {
    console.error("Failed to fetch suppliers:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* uploadAuditReportWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);
  const formData = new FormData();

  const requestURL = AUDITS.UPLOAD_REPORT.replace(
    "<ORGANISATION_ID>",
    organisationId
  )
    .replace("<SUPPLIER_ID>", action.payload.supplierId)
    .replace("<FACTORY_ID>", action.payload.factoryId)
    .replace("<AUDIT_ID>", action.payload.auditId);

  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: authToken,
  };
  if (action.payload.auditId === "none") {
    let fileUpload = {};

    for (let file of action.payload.file) {
      fileUpload[file.id] = false;

      const formData = new FormData();
      formData.append("file", file.file);
      try {
        const response = yield axios.post(requestURL, formData, {
          headers,
        });

        if (response && response.status === 200 && response.data) {
          if (response.data.success) {
            fileUpload[file.id] = true;
            // yield put(setUploadAuditReportFiles(response.data.results));
            // yield put(setAuditData(response.data.results.auditData));
            // yield put(setAuditIssueCAPData(response.data.results.capDetails));
          }
        }
      } catch (error) {
        console.error("Failed to fetch suppliers:", error);
        if (error && error.response && error.response.status === 401) {
          yield put(logout());
        }
        // Handle errors here, possibly dispatching a failure action
      }
    }
    yield put(setFilesUploadedIds(fileUpload));
  } else {
    formData.append("file", action.payload.file);

    try {
      const response = yield axios.post(requestURL, formData, {
        headers,
      });

      if (response && response.status === 200 && response.data) {
        if (response.data.success) {
          yield put(setUploadAuditReportFiles(response.data.results));
          // yield put(setAuditData(response.data.results.auditData));
          // yield put(setAuditIssueCAPData(response.data.results.capDetails));
        }
      }
    } catch (error) {
      console.error("Failed to fetch suppliers:", error);
      if (error && error.response && error.response.status === 401) {
        yield put(logout());
      }
      // Handle errors here, possibly dispatching a failure action
    }
  }
}

function* processAuditReportWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);
  const formData = new FormData();

  const requestURL = AUDITS.PROCESS_REPORT.replace(
    "<ORGANISATION_ID>",
    organisationId
  )
    .replace("<SUPPLIER_ID>", action.payload.supplierId)
    .replace("<FACTORY_ID>", action.payload.factoryId)
    .replace("<AUDIT_ID>", action.payload.auditId);

  console.log(`Sending request to URL: ${requestURL}`);
  console.log(`Endpoint: PROCESS_REPORT`);

  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: authToken,
  };
  if (action.payload.auditId === "none") {
    let fileUpload = {};

    for (let file of action.payload.file) {
      fileUpload[file.id] = false;

      const formData = new FormData();
      formData.append("file", file.file);
      try {
        const response = yield axios.post(requestURL, formData, {
          headers,
        });

        if (response && response.status === 200 && response.data) {
          if (response.data.success) {
            fileUpload[file.id] = true;
            // yield put(setProcessAuditReportFiles(response.data.results));
          }
        }
      } catch (error) {
        console.error("Failed to process audit report:", error);
        if (error && error.response && error.response.status === 401) {
          yield put(logout());
        }
        // Handle errors here, possibly dispatching a failure action
      }
    }
    yield put(setFilesUploadedIds(fileUpload));
  } else {
    formData.append("file", action.payload.file);

    try {
      const response = yield axios.post(requestURL, formData, {
        headers,
      });

      if (response && response.status === 200 && response.data) {
        if (response.data.success) {
          yield put(setProcessAuditReportFiles(response.data.results));
        }
      }
    } catch (error) {
      console.error("Failed to process audit report:", error);
      if (error && error.response && error.response.status === 401) {
        yield put(logout());
      }
      // Handle errors here, possibly dispatching a failure action
    }
  }
}

function* regenerateCapWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = AUDITS.REGENERATE_CAP.replace(
    "<ORGANISATION_ID>",
    organisationId
  )
    .replace("<SUPPLIER_ID>", action.payload.supplierId)
    .replace("<FACTORY_ID>", action.payload.factoryId)
    .replace("<AUDIT_ID>", action.payload.auditId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  try {
    const response = yield axios.put(requestURL, {}, { headers });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setRegenerateCapResult(response.data.results));
      }
    }
  } catch (error) {
    console.error("Failed to regenerate CAP:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* deleteCapDetailsByIdWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = AUDITS.DELETE_CAP_DETAILS_BY_ID.replace(
    "<ORGANISATION_ID>",
    organisationId
  )
    .replace("<SUPPLIER_ID>", action.payload.supplierId)
    .replace("<FACTORY_ID>", action.payload.factoryId)
    .replace("<AUDIT_ID>", action.payload.auditId)
    .replace("<FINDING_ID>", action.payload.capDetailsId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  try {
    const response = yield axios.delete(requestURL, { headers });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setAuditData(response.data.results.auditData));
        yield put(setAuditIssueCAPData(response.data.results.capDetails));
      }
    }
  } catch (error) {
    console.error("Failed to delete cap details:", error);
  }
}

function* watchAuditSaga() {
  yield takeLatest(getListOfAllAudits.type, getListOfAuditsWorker);
}

function* watchAuditByIdSaga() {
  yield takeLatest(getAuditById.type, getAuditByIdWorker);
}

function* watchUpdateAuditDataSaga() {
  yield takeLatest(updateAuditData.type, updateAuditDataWorker);
}

function* watchUploadAuditReportSaga() {
  yield takeLatest(uploadAuditReport.type, uploadAuditReportWorker);
}

function* watchProcessAuditReportSaga() {
  yield takeLatest(processAuditReport.type, processAuditReportWorker);
}

function* watchRegenerateCapSaga() {
  yield takeLatest(regenerateCap.type, regenerateCapWorker);
}

function* watchDeleteCapDetailsByIdSaga() {
  yield takeLatest(deleteCapDetailsById.type, deleteCapDetailsByIdWorker);
}

function* createAuditCapDetailWorker(action) {
  try {
    console.log("Creating audit cap detail with action:", action);
    const authToken = yield select(selectAuthToken);
    console.log("Auth token available:", !!authToken);
    const { organisationId, supplierId, factoryId, auditId, capDetailData } = action.payload;
    console.log("Payload values:", { organisationId, supplierId, factoryId, auditId });
    console.log("Cap Detail Data:", capDetailData);

    const requestURL = AUDITS.CREATE_CAP_DETAIL.replace(
      "<ORGANISATION_ID>",
      organisationId
    )
      .replace("<SUPPLIER_ID>", supplierId)
      .replace("<FACTORY_ID>", factoryId)
      .replace("<AUDIT_ID>", auditId);

    console.log("Making request to URL:", requestURL);

    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };

    console.log("Sending request with headers:", headers);
    const response = yield axios.post(requestURL, capDetailData, { headers });
    console.log("Received response:", response.status, response.data);

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        console.log("Request successful, updating state...");
        yield put(setAuditData(response.data.results.auditData));
        yield put(setAuditIssueCAPData(response.data.results.capDetails));
        yield put(setCreateCapDetailResult(response.data.results));
        console.log("State updated with new finding data");
      } else {
        console.error("API returned success: false", response.data);
      }
    }
  } catch (error) {
    console.error("Failed to create audit cap detail:", error);
    console.error("Error details:", error.response?.data);
    console.error("Error status:", error.response?.status);
    console.error("Error message:", error.message);
    if (error && error.response && error.response.status === 401) {
      console.log("Unauthorized, logging out...");
      yield put(logout());
    }
  }
}

function* watchCreateAuditCapDetailSaga() {
  yield takeLatest(createAuditCapDetail.type, createAuditCapDetailWorker);
}

export default function* rootSaga() {
  yield all([
    watchAuditSaga(),
    watchAuditByIdSaga(),
    watchUpdateAuditDataSaga(),
    watchUploadAuditReportSaga(),
    watchProcessAuditReportSaga(),
    watchRegenerateCapSaga(),
    watchDeleteCapDetailsByIdSaga(),
    watchCreateAuditCapDetailSaga(),
  ]);
}