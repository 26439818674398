import React, { useEffect, useState } from "react";
import { Grid, Box, Divider, Typography, CircularProgress, Tabs, Tab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectOrganisationId, selectUserInfo } from "../selectors/login.selector";
import { getAnalyticsUrlStart } from "../reducers/analytics.reducer";
import { selectAnalyticsUrl, selectAnalyticsLoading, selectAnalyticsError } from "../selectors/analytics.selector";
import SupplierMetaDataGrid from "../components/InsightDetails/supplierMetaDataGrid.component";
import FacilityMetaDataGrid from "../components/InsightDetails/facilityMetaDataGrid.component";
import InsightDashboard from "../components/InsightDetails/insightDashboard.component";
import RiskAnalytics from "../components/InsightDetails/riskAnalytics.component";
import TopBreadcrumbs from "../components/topBreadcrumbs.component";
import LeftSideNavbar from "../components/leftSideNavbar.component";
import { PageStyles } from "../styles";

const Insights = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const organisationId = useSelector(selectOrganisationId);
  const userInfo = useSelector(selectUserInfo);
  const iframeUrl = useSelector(selectAnalyticsUrl);
  const isLoading = useSelector(selectAnalyticsLoading);
  const error = useSelector(selectAnalyticsError);

  // const [selectedTab, setSelectedTab] = useState(0);

  // useEffect(() => {
  //   if (organisationId) {
  //     const dashboardId = selectedTab === 0 ? "10" : "34";
  //     dispatch(getAnalyticsUrlStart({ organisationId, dashboardId }));
  //   }
  // }, [organisationId, selectedTab, dispatch]);

  // const handleTabChange = (event, newValue) => {
  //   setSelectedTab(newValue);
  // };

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  const [selectedTab, setSelectedTab] = useState("dataAnalytics");

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSideNavbar />
      <Box sx={{ flex: 1,overflowY: "hidden" }}>
        <Box sx={{
          ...PageStyles.containerBoxExceptNavbar,
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          backgroundColor: 'white', // Assuming a white background, adjust if needed
        }}>
          <Box sx={PageStyles.firstSectionWithBreadcrumbsAndName}>
            <TopBreadcrumbs />
            <Typography variant="h6" style={{ fontSize: 20, fontWeight: 600 }}>
              Insight
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}
            >
              Insight for your supply chain
            </Typography>
          </Box>
          <Divider />
        </Box>

        <Box sx={{ flex: 1, pt:3, px:5}}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="insight tabs"
            variant="fullWidth"
            sx={{
              "& .MuiTab-root": {
                textTransform: "none",
                flexGrow: 1,
                maxWidth: "none",
              },
              "& .Mui-selected": {
                backgroundColor: "rgba(13, 71, 161, 0.04)", // 0D47A1 with 4% opacity
              }
            }}
          >
            <Tab label="Data Analytics" value="dataAnalytics" />
            <Tab label="Risk" value="risk" />
            <Tab label="Approved Factories Table" value="facilitiesDataTable" />
            <Tab label="Vendors Data Table" value="supplierDataTable" />
          </Tabs>
          
          <Box>
          {selectedTab === "dataAnalytics" && <InsightDashboard />}
          </Box>
          <Box sx={{ mt: 5 }}>
            
            {selectedTab === "risk" && <RiskAnalytics />}
            {selectedTab === "supplierDataTable" && <SupplierMetaDataGrid />}
            {selectedTab === "facilitiesDataTable" && <FacilityMetaDataGrid />}
          </Box>
        </Box>
      </Box>
    </Box>
    // <Grid container sx={{ height: "100vh" }}>
    //   <Grid item xs={2}>
    //     <LeftSideNavbar />
    //   </Grid>
    //   <Grid item xs={10} sx={{ display: "flex", flexDirection: "column" }}>
    //     <Box
    //       sx={{
    //         flex: "none",
    //         margin: "0 20px",
    //         padding: "12px 22px",
    //       }}
    //     >
    //       <TopBreadcrumbs />
    //       <Typography variant="h6" style={{ fontSize: 20, fontWeight: 600 }}>
    //         Insights
    //       </Typography>
    //       <Typography
    //         variant="body2"
    //         color="textSecondary"
    //         style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}
    //       >
    //         View and analyze insights here
    //       </Typography>
    //       <Divider />
    //     </Box>
    //     <Tabs value={selectedTab} onChange={handleTabChange} centered>
    //       <Tab label="Analytics" />
    //       <Tab label="Factory Data" />
    //     </Tabs>
    //     <Box
    //       sx={{
    //         flexGrow: 1,
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "center",
    //         flexDirection: "column",
    //         position: "relative",
    //         overflow: "hidden",
    //       }}
    //     >
    //       {isLoading ? (
    //         <CircularProgress />
    //       ) : (
    //         <Box
    //           sx={{
    //             width: "100%",
    //             height: "100%",
    //             position: "relative",
    //             overflow: "hidden",
    //           }}
    //         >
    //           <Box
    //             sx={{
    //               width: "100%",
    //               height: "calc(100% + 60px)",
    //               position: "absolute",
    //               top: 0,
    //               left: 0,
    //               right: 0,
    //               overflow: "hidden",
    //             }}
    //           >
    //             <iframe
    //               src={iframeUrl}
    //               frameBorder="0"
    //               width="100%"
    //               height="100%"
    //               allowTransparency
    //               style={{
    //                 border: "none",
    //                 position: "absolute",
    //                 top: 0,
    //                 left: 0,
    //                 right: 0,
    //                 bottom: "-30px",
    //               }}
    //             ></iframe>
    //           </Box>
    //         </Box>
    //       )}
    //     </Box>
    //   </Grid>
    // </Grid>
  );
};

export default Insights;
