import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Button,
  Typography,
} from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomChipLabel from "./customChipLabel.component";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedAuditIssueId,
  deleteCapDetailsById,
} from "../../reducers/audit.reducer";
import { selectAuditIssueCAPData } from "../../selectors/audit.selector";
import { selectEditModeState } from "../../selectors/misc.selector";
import { useParams } from "react-router-dom";
import CreateFindingModal from "./createFindingModal.component";

const AuditsTable = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const handleClick = (id) => {
    dispatch(setSelectedAuditIssueId(id));
  };
  const handleDelete = (capDetailsId) => {
    dispatch(
      deleteCapDetailsById({
        organisationId: params.organisationId,
        supplierId: params.supplierId,
        factoryId: params.factoryId,
        auditId: params.auditId,
        capDetailsId: capDetailsId,
      })
    );
  };

  const editMode = useSelector(selectEditModeState);
  const issuesData = useSelector(selectAuditIssueCAPData);

  // Define severity order
  const severityOrder = {
    ZT: 1,
    HR: 2,
    Major: 3,
    Minor: 4,
    "N/A": 5,
  };

  // Sort issuesData based on severity
  const sortedIssuesData = issuesData.slice().sort((a, b) => {
    return severityOrder[a.severity] - severityOrder[b.severity];
  });

  return (
    <>
      <Box
        elevation={1}
        sx={{
          p: 3,
          borderTop: "0px",
          px: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6">Audit Findings</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsCreateModalOpen(true)}
            sx={{
              borderRadius: "8px",
              textTransform: "none",
              fontWeight: 600,
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
            }}
          >
            Create New Finding
          </Button>
        </Box>
        <Table aria-label="issues table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Issues</TableCell>
              <TableCell sx={{ width: "12px" }}></TableCell>
              <TableCell sx={{ p: 0 }}>Type</TableCell>
              <TableCell sx={{ p: 0 }}>Severity</TableCell>
              <TableCell>Status</TableCell>
              <TableCell sx={{ p: 0 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedIssuesData.map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  sx={{
                    wordBreak: "break-word",
                    mr: 0,
                    pr: 1,
                    maxWidth: "470px",
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{
                    wordBreak: "break-word",
                    mr: 0,
                    pr: 1,
                    maxWidth: "470px",
                  }}
                >
                  {row.issue}
                </TableCell>
                <TableCell
                  sx={{
                    width: "16px",
                    cursor: "pointer",
                    paddingRight: "40px",
                    opacity: 0.5,
                  }}
                  onClick={() => {
                    handleClick(row.id);
                  }}
                >
                  <IconButton
                    sx={{
                      color: "rgba(0, 0, 0, 0.6)",
                      padding: 0,
                      margin: 0,
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <OpenInFullIcon className="icon" />
                    <Typography variant="caption" sx={{ ml: 1 }}>
                      See detail
                    </Typography>
                  </IconButton>
                </TableCell>
                <TableCell sx={{ p: 0 }}>{row.type}</TableCell>
                <TableCell sx={{ p: 0 }}>
                  <CustomChipLabel severity={row.severity} />
                </TableCell>
                <TableCell>
                  <CustomChipLabel
                    cap={row.statusChipLabel}
                    capLabel={row.status}
                  />
                </TableCell>
                <TableCell sx={{ p: 1 }}>
                  {editMode && (
                    <Button onClick={() => handleDelete(row.id)}>
                      <DeleteIcon sx={{ color: "gray" }} />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <CreateFindingModal 
        open={isCreateModalOpen} 
        onClose={() => setIsCreateModalOpen(false)} 
      />
    </>
  );
};

export default AuditsTable;
