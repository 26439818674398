import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider, CssBaseline } from "@mui/material";

import Login from "./components/login.component";
import IndexPage from "./pages/index.page";
import FacilityDetails from "./pages/facilityDetails.page";
import theme from "./utils/theme";
import { selectLoggedIn } from "./selectors/login.selector";
import { loginSuccess } from "./reducers/login.reducer";
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey('fb9ee2a0afec9d75cd64230695492848Tz0xMDExMDYsRT0xNzYyMTA3MzQwMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=');


function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectLoggedIn);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const storedState = localStorage.getItem("loginState");
    if (storedState) {
      const state = JSON.parse(storedState);
      if (state && state._id && state.token) {
        dispatch(loginSuccess(state));
      }
    }
    setLoading(false); // Set loading to false after checking login state
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>; // Render loading indicator while determining login state
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route
            path="/organisations/:organisationId/suppliers/:supplierId/factories/:factoryId"
            element={isLoggedIn ? <FacilityDetails /> : <Navigate to="/" replace />}
          />
          <Route
            path="/"
            element={
              !isLoggedIn ? <Login /> : <Navigate to="/factories" replace />
            }
          />
          <Route
            path="*"
            element={isLoggedIn ? <IndexPage /> : <Navigate to="/" replace />}
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App