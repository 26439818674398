import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import IconButton from "@mui/material/IconButton";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../reducers/login.reducer";
import { selectUserInfo } from "../selectors/login.selector";
import { setLeftSideNavigationSelected } from "../reducers/navigation.reducer";
import { selectLeftSideNavigationSelected } from "../selectors/navigation.selector";

const LeftSideNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const userInfo = useSelector(selectUserInfo);
  const selected = useSelector(selectLeftSideNavigationSelected);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const handleNavigate = (path) => {
    dispatch(setLeftSideNavigationSelected(path));
    navigate(path);
  };
  useEffect(() => {
    if (params["*"] !== "suppliers") {
      dispatch(setLeftSideNavigationSelected(`/${params["*"]}`));
    }
  }, [params]);
  return (
    <Box
      sx={{
        maxWidth: 210,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        padding: 2,
        borderRight: "1px solid #cccccc4a",
        borderBottom: "none",
        borderLeft: "none",
        borderTop: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          py: 2,
          px: 1,
        }}
      >
        <img src="/logo.svg" alt="Logo" style={{ maxWidth: 40, height: 40 }} />
      </Box>

      <List sx={{ width: "100%" }}>
        <ListItemButton
          onClick={() => handleNavigate(`/suppliers`)}
          selected={selected === "/suppliers"}
          sx={{
            bgcolor: selected === "/suppliers" ? "action.selected" : "inherit",
          }}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <img
              src="/suppliers.svg"
              alt="Suppliers"
              style={{ maxWidth: 24, height: 24 }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Vendors"
            primaryTypographyProps={{
              sx: {
                color: "#344054",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "24px",
              },
            }}
          />
        </ListItemButton>
        <ListItemButton
          onClick={() => handleNavigate(`/factories`)}
          selected={selected === "/factories"}
          sx={{
            bgcolor: selected === "/factories" ? "action.selected" : "inherit",
          }}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <img
              src="/factories.svg"
              alt="Factories"
              style={{ maxWidth: 24, height: 24 }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Factories"
            primaryTypographyProps={{
              sx: {
                color: "#344054",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "24px",
              },
            }}
          />
        </ListItemButton>
        <ListItemButton
          onClick={() => handleNavigate(`/inactive-factories`)}
          selected={selected === "/inactive-factories"}
          sx={{
            bgcolor:
              selected === "/inactive-factories"
                ? "action.selected"
                : "inherit",
          }}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <img
              src="/factories.svg"
              alt="Inactive Factories"
              style={{ maxWidth: 24, height: 24 }}
            />
          </ListItemIcon>
          <ListItemText
            primary="Inactive Factories"
            primaryTypographyProps={{
              sx: {
                color: "#344054",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "24px",
              },
            }}
          />
        </ListItemButton>
        <ListItemButton
          onClick={() => handleNavigate(`/insights`)}
          selected={selected === "/insights"}
          sx={{
            bgcolor: selected === "/insights" ? "action.selected" : "inherit",
          }}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <img
              src="/insights.svg"
              alt="Insights"
              style={{ width: 24, height: 24 }}
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              sx: {
                color: "#344054",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "24px",
              },
            }}
            primary="Insights"
          />
        </ListItemButton>
        <ListItemButton
          onClick={() => handleNavigate(`/risk`)}
          selected={selected === "/risk"}
          sx={{
            bgcolor: selected === "/risk" ? "action.selected" : "inherit",
          }}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <img
              src="/risk.svg"
              alt="Due Action"
              style={{ maxWidth: 24, height: 24 }}
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              sx: {
                color: "#344054",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "24px",
              },
            }}
            primary="Risk"
          />
        </ListItemButton>
      </List>

      <Box sx={{ flexGrow: 1 }} />

      <Divider sx={{ width: "100%", marginBottom: 2 }} />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: "10px",
          py: 2,
        }}
      >
        <Box>
          <Typography variant="body2" style={{ fontWeight: 600 }}>
            {userInfo.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {/* {userInfo.email} */}
          </Typography>
        </Box>
        <IconButton
          edge="end"
          aria-label="Logout"
          onClick={() => handleLogout()}
        >
          <img
            src="/logout.svg"
            alt="Logout"
            style={{ maxWidth: 24, height: 24 }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default LeftSideNavbar;
