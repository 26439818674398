import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Button,
  Divider,
  InputAdornment,
  Paper,
  Chip,
  CircularProgress,
} from "@mui/material";
import moment from "moment";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TopBreadcrumbs from "./topBreadcrumbs.component";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  getListOfAllSuppliers,
  getSearchResultOfSuppliers,
  getSupplierById,
} from "../reducers/supplier.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
  selectSearchCount,
  selectSearchResultsSupplierList,
  selectSupplierCache,
  selectSupplierList,
  selectTotalCount,
} from "../selectors/supplier.selector";
import { getListOfAllFactories } from "../reducers/factory.reducer";

const SupplierTable = () => {
  const dispatch = useDispatch();
  const suppliersList = useSelector(selectSupplierList);

  const totalCount = useSelector(selectTotalCount);
  const searchCount = useSelector(selectSearchCount);
  const searchResults = useSelector(selectSearchResultsSupplierList);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [count, setCount] = useState(totalCount);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [loading, setLoading] = useState(true);

  const supplierCache = useSelector((state) =>
    selectSupplierCache(state, page)
  );
  const navigate = useNavigate();

  useEffect(() => {
    setCount(totalCount);
  }, [totalCount]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (supplierCache) {
        setFilteredSuppliers(supplierCache);
        setLoading(false);
      } else {
        const limit = 8;
        const offset = (page - 1) * limit;
        await dispatch(getListOfAllSuppliers({ page, limit, offset }));
        setLoading(false);
      }
    };
    fetchData();
  }, [page, supplierCache, dispatch]);

  useEffect(() => {
    if (searchTerm !== "") {
      let filtered = suppliersList.filter((supplier) =>
        Object.values(supplier).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );

      if (filtered.length === 0 && searchTerm !== "") {
        dispatch(getSearchResultOfSuppliers({ search: searchTerm }));
        setCount(searchCount);
        filtered = searchResults;
      }
      setFilteredSuppliers(filtered);
    } else {
      setFilteredSuppliers(supplierCache);
    }
  }, [suppliersList, searchTerm, searchResults]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value === "") {
      setCount(totalCount);
    }
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedSuppliers = React.useMemo(() => {
    if (sortConfig.key) {
      return [...filteredSuppliers].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredSuppliers || [];
  }, [filteredSuppliers, sortConfig]);

  const location = useLocation();

  const navigateToSupplierDetails = (supplierId) => {
    dispatch(getSupplierById(supplierId));
    dispatch(getListOfAllFactories({ limit: 8, offset: 0, supplierId }));
    navigate(`${location.pathname}/${supplierId}`);
  };

  return (
    <Box sx={{ p: 2, mr: 2 }}>
      <TopBreadcrumbs />
      <Typography variant="h6" style={{ fontSize: 20, fontWeight: 600 }}>
        Vendors
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}
      >
        Manage your vendors here
      </Typography>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 2 }}>
        <TextField
          label="Search vendor"
          value={searchTerm}
          onChange={handleSearch}
          variant="outlined"
          size="small"
          sx={{ width: "300px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src="./search.svg" alt="Search" />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead
              sx={{
                backgroundColor: (theme) => theme.palette.grey[200],
              }}
            >
              <TableRow>
                <TableCell sx={{ width: "20%" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        color: "#475467",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Vendor Name
                    </Typography>
                    <SwapVertIcon
                      sx={{
                        cursor: "pointer",
                        marginLeft: "4px",
                      }}
                      onClick={() => handleSort("name")}
                    />
                  </Box>
                </TableCell>
                <TableCell sx={{ width: "15%" }}>Business Unit</TableCell>
                <TableCell sx={{ width: "15%" }}>Vendor Status</TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    color: "#475467",
                    fontSize: "16px",
                    fontWeight: 500,
                    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  Total Factory
                </TableCell>
                <TableCell sx={{ width: "10%", color: "#475467", fontSize: "16px", fontWeight: 500 }}>
                  Tier 1
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    color: "#475467",
                    fontSize: "16px",
                    fontWeight: 500,
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  Tier 2
                </TableCell>
                <TableCell sx={{ width: "10%" }}>Action Alert</TableCell>
                <TableCell sx={{ width: "10%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedSuppliers && sortedSuppliers.length > 0 ? (
                sortedSuppliers.map((supplier) => (
                  <TableRow
                    key={supplier.supplierId}
                    sx={{
                      "& td": {
                        padding: "8px 16px",
                        borderBottom: (theme) =>
                          `1px solid ${theme.palette.divider}`,
                      },
                    }}
                  >
                    <TableCell>{supplier.name}</TableCell>
                    <TableCell>{supplier.businessUnit}</TableCell>
                    <TableCell
                      sx={{
                        color: supplier?.metadata?.vendorStatus?.includes("Approved")
                          ? "green"
                          : "inherit",
                      }}
                    >
                      {supplier?.metadata?.vendorStatus}
                    </TableCell>
                    <TableCell sx={{ borderLeft: "1px solid rgba(0, 0, 0, 0.12)" }}>
                      {supplier.totalFactories || "-"}
                    </TableCell>
                    <TableCell>{supplier.tier1}</TableCell>
                    <TableCell sx={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                      {supplier.tier2}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={
                          moment(supplier?.nextAuditDate).diff(moment(), 'days') < 0
                            ? "Yes"
                            : "No"
                        }
                        sx={{
                          borderRadius: "16px",
                          fontWeight: 500,
                          fontSize: 12,
                          backgroundColor:
                            moment(supplier?.nextAuditDate).diff(moment(), 'days') < 0 || supplier?.actionAlert == "Yes"
                              ? "#FECDCA"
                              : "#F2F4F7",
                          color:
                            moment(supplier?.nextAuditDate).diff(moment(), 'days') < 0 || supplier?.actionAlert == "Yes"
                              ? "#B42318"
                              : "default",
                        }}
                      />{" "}
                    </TableCell>

                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={() =>
                          navigateToSupplierDetails(supplier.supplierId)
                        }
                        sx={{
                          backgroundColor: "#6172F3",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#5667e2",
                            fontWeight: "bold",
                          },
                          textTransform: "none",
                          width: "79px",
                          height: "40px",
                          borderRadius: "8px",
                        }}
                        size="small"
                      >
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} sx={{ textAlign: "center" }}>
                    No suppliers found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "12px 24px 16px 24px",
          }}
        >
          <Button
            variant="outlined"
            disabled={page === 1}
            startIcon={<NavigateBeforeIcon />}
            onClick={(e) => handlePageChange(e, page - 1)}
            sx={{
              width: "111px",
              height: "36px",
              padding: "8px 12px",
              gap: "4px",
              borderRadius: "8px",
              border: "1px solid #D0D5DD",
              backgroundColor: "white",
              color: "text.secondary",
              "&:hover": {
                backgroundColor: "white",
                color: "text.primary",
                fontWeight: "bold",
              },

              textTransform: "none",
            }}
          >
            Previous
          </Button>

          <Pagination
            count={count}
            page={page}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            color="primary"
            showFirstButton={false}
            showLastButton={false}
            hideNextButton={true}
            hidePrevButton={true}
            sx={{
              "& .MuiPaginationItem-root": {
                border: "none",
              },
              "& .Mui-selected": {
                backgroundColor: (theme) => theme.palette.primary.main,
                color: "white",
              },
            }}
          />

          <Button
            variant="outlined"
            endIcon={<NavigateNextIcon />}
            disabled={page === count}
            onClick={(e) => handlePageChange(e, page + 1)}
            sx={{
              width: "111px",
              height: "36px",
              padding: "8px 12px",
              gap: "4px",
              borderRadius: "8px",
              border: "1px solid #D0D5DD",
              backgroundColor: "white",
              color: "text.secondary",
              "&:hover": {
                backgroundColor: "white",
                color: "text.primary",
                fontWeight: "bold",
              },

              textTransform: "none",
            }}
          >
            Next
          </Button>
        </Box>
      </TableContainer>
    </Box>
  );
};

export default SupplierTable;
