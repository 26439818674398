import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Link,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";

import { selectAuditData } from "../../selectors/audit.selector";

const ListOfAuditDocuments = ({ includeType = "export", disableMode = false }) => {
  const selectedAuditData = useSelector(selectAuditData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!selectedAuditData) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [selectedAuditData]);

  if (!selectedAuditData || selectedAuditData.files.length === 0) {
    return null;
  }

  const isExportType = includeType === "export" && disableMode;

  return (
    <Card
      style={{
        padding: "0px 32px",
        boxShadow:
          "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
        border: "1px solid rgba(234, 236, 240, 1)",
        borderRadius: "12px",
        marginTop: 8,
      }}
    >
      <CardContent>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Grid item>
            <Typography
              sx={{ fontWeight: 700, fontSize: 16, color: "#344054" }}
            >
              Excel CAP Export
            </Typography>
          </Grid>
        </Grid>

        {/* Actual information */}
        {isExportType ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              borderRadius: "8px",
              border: "1px dashed rgba(0, 0, 0, 0.2)",
              p: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.6)",
                fontStyle: "italic",
                textAlign: "center",
              }}
            >
              Temporarily disabled to implement requested customizations
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={2} sx={{ mb: 1 }}>
            {selectedAuditData.files
              ?.filter((audit) => audit.type === includeType)
              .map((audit) => (
                <Grid item xs={2} sx={{ mx: 1 }} key={audit.url}>
                  <Link
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: "#45464E",
                      textDecoration: "underline",
                      textDecorationColor: "#45464E",
                      "&:hover": {
                        textDecorationColor: "rgba(69, 70, 78, 0.8)",
                      },
                    }}
                    href={audit.url}
                    target="_blank"
                  >
                    {audit?.name}
                  </Link>
                </Grid>
              ))}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default ListOfAuditDocuments;
