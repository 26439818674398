import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  Grid,
  TextField,
  MenuItem,
  ClickAwayListener,
  Popper,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { selectUserInfo } from "../selectors/login.selector";
import { selectFactoryData } from "../selectors/factory.selector";
import { updateFactoryData } from "../reducers/factory.reducer";
import { updateSupplierData } from "../reducers/supplier.reducer";
import { selectUsers } from "../selectors/user.selector";
import { getUsersByOrganisationId } from "../reducers/user.reducer";
import { selectSupplierData } from "../selectors/supplier.selector";
import { sendEmailToMentioned } from "../reducers/email.reducer";

const CommentSection = ({ source, type }) => {
  const userInfo = useSelector(selectUserInfo);
  const factoryInfo = useSelector(selectFactoryData);
  const supplierInfo = useSelector(selectSupplierData);
  const dispatch = useDispatch();

  const [comment, setComment] = useState("");
  const [comments, setComments] = useState(() => {
    const selectedInfo = source === "supplier" ? supplierInfo : factoryInfo;
    return Array.isArray(selectedInfo?.metadata?.note)
      ? selectedInfo?.metadata?.note
      : [
          {
            author: "Previously Migrated Note",
            comment: selectedInfo?.metadata?.note,
            createdAt: "",
          },
        ];
  });

  const users = useSelector(selectUsers);
  const [mentionUsers, setMentionUsers] = useState([]);

  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [mentionQuery, setMentionQuery] = useState("");
  const [cursorPosition, setCursorPosition] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [mentionedUsers, setMentionedUsers] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);
  const textFieldRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    dispatch(getUsersByOrganisationId());
  }, [dispatch]);

  useEffect(() => {
    setMentionUsers(
      users.map((user) => ({
        id: user._id,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      }))
    );
  }, [users]);

  useEffect(() => {
    if (source === "supplier") {
      setComments(
        Array.isArray(supplierInfo?.metadata?.note)
          ? supplierInfo?.metadata?.note
          : [
              {
                author: "Previously Migrated Note",
                comment: supplierInfo?.metadata?.note,
                createdAt: "",
              },
            ]
      );
    } else {
      setComments(
        Array.isArray(factoryInfo?.metadata?.note)
          ? factoryInfo?.metadata?.note
          : [
              {
                author: "Previously Migrated Note",
                comment: factoryInfo?.metadata?.note,
                createdAt: "",
              },
            ]
      );
    }
  }, [supplierInfo?.metadata?.note, factoryInfo?.metadata?.note]);

  const getUserNameById = (id) => {
    if (id === "Previously Migrated Note") return id;
    const user = users.find((user) => user._id === id);
    return user ? `${user.firstName} ${user.lastName}` : "Unknown User";
  };

  const handleCommentChange = (e) => {
    const value = e.target.value;
    const selectionStart = e.target.selectionStart;
    setComment(value);

    setMentionedUsers((prevMentionedUsers) =>
      prevMentionedUsers.filter((user) => value.includes(`@${user.name}`))
    );

    const lastAtIndex = value.lastIndexOf("@", selectionStart - 1);
    if (
      lastAtIndex !== -1 &&
      (lastAtIndex === 0 || /\s/.test(value.charAt(lastAtIndex - 1)))
    ) {
      const query = value.slice(lastAtIndex + 1, selectionStart);
      setMentionQuery(query);
      const filtered = mentionUsers.filter(
        (user) =>
          user.name.toLowerCase().startsWith(query.toLowerCase()) &&
          !mentionedUsers.some((mentioned) => mentioned.id === user.id)
      );
      setFilteredUsers(filtered);
      setShowDropdown(true);
      setCursorPosition(lastAtIndex);
    } else {
      setShowDropdown(false);
      setMentionQuery("");
      setFilteredUsers([]);
      setSelectedIndex(0);
    }
  };

  const handleUserSelect = (user) => {
    const textarea = textFieldRef.current;
    const value = comment;
    const selectionEnd = textarea.selectionEnd;
    const mentionText = `@${user.name} `;
    const before = value.substring(0, cursorPosition);
    const after = value.substring(selectionEnd);
    const newValue = before + mentionText + after;
    setComment(newValue);
    setShowDropdown(false);
    setMentionQuery("");
    setFilteredUsers([]);
    setSelectedIndex(0);

    setMentionedUsers((prevMentionedUsers) => {
      if (!prevMentionedUsers.some((u) => u.id === user.id)) {
        return [...prevMentionedUsers, user];
      }
      return prevMentionedUsers;
    });

    const newCursorPosition = before.length + mentionText.length;
    setTimeout(() => {
      textarea.setSelectionRange(newCursorPosition, newCursorPosition);
      textarea.focus();
    }, 0);
  };

  const handleKeyDown = (e) => {
    console.log(mentionedUsers);
    if (showDropdown && filteredUsers.length > 0) {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setSelectedIndex((prevIndex) => (prevIndex + 1) % filteredUsers.length);
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setSelectedIndex(
          (prevIndex) =>
            (prevIndex - 1 + filteredUsers.length) % filteredUsers.length
        );
      } else if (e.key === "Enter" || e.key === "Tab") {
        e.preventDefault();
        handleUserSelect(filteredUsers[selectedIndex]);
      } else if (e.key === "Escape") {
        setShowDropdown(false);
      }
    }
  };

  const handlePostComment = () => {
    if (!comment) return;

    const currentDate = new Date();
    const isoString = currentDate.toISOString();
    const changes = {
      note: [
        { comment: comment, author: userInfo?.userId, createdAt: isoString },
        ...comments,
      ],
    };

    if (source === "supplier") {
      dispatch(updateSupplierData({ supplierId: supplierInfo?._id, changes }));
    } else {
      dispatch(
        updateFactoryData({
          organisationId: factoryInfo?.organisationId,
          supplierId: factoryInfo?.supplierId,
          factoryId: factoryInfo?._id,
          changes,
        })
      );
    }

    if (mentionedUsers.length > 0) {
      const mentionedEmails = mentionedUsers.map((user) => user.email);
      console.log(
        JSON.stringify({
          organisationId:
            source === "supplier"
              ? supplierInfo?.organisationId
              : factoryInfo?.organisationId,
          supplierId: supplierInfo?._id,
          factoryId: factoryInfo?._id,
          supplierName: supplierInfo?.name,
          factoryName: factoryInfo?.name,
          mentionedEmails,
          commentAuthor: userInfo?.fullName,
          comment,
        })
      );
      dispatch(
        sendEmailToMentioned({
          organisationId:
            source === "supplier"
              ? supplierInfo?.organisationId
              : factoryInfo?.organisationId,
          supplierId: supplierInfo?._id,
          factoryId: factoryInfo?._id,
          supplierName: supplierInfo?.name,
          factoryName: factoryInfo?.name,
          mentionedEmails,
          commentAuthor: userInfo?.fullName,
          comment,
        })
      );
    }

    setComment("");
    setMentionedUsers([]);
  };

  const handleDeleteComment = (index) => {
    const updatedComments = comments.filter((_, i) => i !== index);
    setComments(updatedComments);

    if (source === "supplier") {
      const changes = {
        note: updatedComments,
      };
      dispatch(
        updateSupplierData({
          supplierId: supplierInfo?._id,
          changes,
        })
      );
    } else {
      const changes = {
        note: updatedComments,
      };
      dispatch(
        updateFactoryData({
          organisationId: factoryInfo?.organisationId,
          supplierId: factoryInfo?.supplierId,
          factoryId: factoryInfo?._id,
          changes,
        })
      );
    }
  };

  const handleDeleteClick = (index) => {
    setCommentToDelete(index);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    handleDeleteComment(commentToDelete);
    setDeleteDialogOpen(false);
  };

  const handleClickAway = (event) => {
    if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
      return;
    }
    setShowDropdown(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: "auto",
        pl: 2,
        pr: 2,
        fontFamily: "Inter, sans-serif",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              height: 220,
              width: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              border: "1px solid #ccc",
              borderRadius: 1,
              p: 1,
              bgcolor: "#fafafa",
            }}
          >
            <List>
              {comments.map((commentItem, index) => (
                <ListItem
                  key={index}
                  alignItems="flex-start"
                  sx={{ paddingTop: "0px" }}
                >
                  <ListItemText
                    primary={commentItem.comment}
                    secondary={
                      commentItem.author === "Previously Migrated Note"
                        ? `${commentItem.author}`
                        : `${getUserNameById(commentItem.author)} (${new Date(
                            commentItem.createdAt
                          ).toLocaleString()})`
                    }
                  />
                  {commentItem.author == userInfo.userId ? (
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteClick(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </ListItem>
              ))}
            </List>
            <Dialog
              open={deleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
            >
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this comment?
                </DialogContentText>
                <Box
                  sx={{
                    mt: 2,
                    p: 2,
                    bgcolor: "#f5f5f5",
                    border: "1px solid #ddd",
                    borderRadius: 1,
                  }}
                >
                  <ListItemText
                    primary={comments[commentToDelete]?.comment}
                    secondary={
                      comments[commentToDelete]?.author ===
                      "Previously Migrated Note"
                        ? `${comments[commentToDelete]?.author}`
                        : `${getUserNameById(
                            comments[commentToDelete]?.author
                          )} (${new Date(
                            comments[commentToDelete]?.createdAt
                          ).toLocaleString()})`
                    }
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDeleteDialogOpen(false)}>
                  Cancel
                </Button>
                <Button onClick={handleConfirmDelete} color="error" autoFocus>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>
        {type !== "view" && (
          <Grid item xs={12} md={4}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Box>
                <TextField
                  label="Your comment"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={5}
                  value={comment}
                  onChange={handleCommentChange}
                  onKeyDown={handleKeyDown}
                  inputRef={textFieldRef}
                />
                {showDropdown && (
                  <Popper
                    open
                    anchorEl={textFieldRef.current}
                    style={{ zIndex: 1500 }}
                  >
                    <Paper
                      ref={dropdownRef}
                      style={{ maxHeight: 200, overflowY: "auto" }}
                    >
                      {filteredUsers.map((user, index) => (
                        <MenuItem
                          key={user.id}
                          selected={index === selectedIndex}
                          onClick={() => handleUserSelect(user)}
                        >
                          {user.name}
                        </MenuItem>
                      ))}
                    </Paper>
                  </Popper>
                )}
              </Box>
            </ClickAwayListener>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePostComment}
              disabled={!comment}
              sx={{ mt: 2 }}
            >
              Post
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CommentSection;
