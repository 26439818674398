import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getFactoryById,
  getListOfAllFactories,
} from "../../reducers/factory.reducer";
import {
  selectFactoryListBySupplierId,
  selectTierCounts,
} from "../../selectors/factory.selector";
import { selectLoadingState } from "../../selectors/misc.selector";
import ListCard from "../listCard.component";

const FacilityComplianceList = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [tierFilter, setTierFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const isLoading = useSelector(selectLoadingState);

  const issuesColorScheme = (status) => {
    switch (status) {
      case "zt":
        return "#D92D20";
      case "critical":
        return "#FF692E";
      case "major":
        return "#FDB022";
      default:
        return "#45464E";
    }
  };

  // Get all facilities for this supplier from the Redux store
  const facilities = useSelector((state) =>
    selectFactoryListBySupplierId(state, params.supplierId)
  );

  const { tier1, tier2, total } = useSelector(selectTierCounts);

  // Apply tier filter in the UI
  const filteredFacilities = facilities.filter(facility => 
    (tierFilter === "" || facility?.metadata?.tier === tierFilter) &&
    (statusFilter === "" || facility?.metadata?.status === statusFilter)
  );

  const navigateToFacilityDetails = (factoryId) => {
    dispatch(
      getFactoryById({ supplierId: params.supplierId, factoryId: factoryId })
    );
    navigate(
      `/organisations/${params.organisationId}/suppliers/${params.supplierId}/factories/${factoryId}`
    );
  };

  useEffect(() => {
    dispatch(
      getListOfAllFactories({
        supplierId: params.supplierId,
        supplierFactoryList: true  // Flag to indicate we're fetching all factories for a supplier
      })
    );
  }, [dispatch, params.supplierId]);

  return (
    <Box mt={2}>
      <Box sx={{ mb: 2 }}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="tier-filter-label">Filter by Tier</InputLabel>
          <Select
            labelId="tier-filter-label"
            value={tierFilter}
            label="Filter by Tier"
            onChange={(e) => setTierFilter(e.target.value)}
          >
            <MenuItem value="">All ({total})</MenuItem>
            <MenuItem value={"1"}>Tier 1 ({tier1})</MenuItem>
            <MenuItem value={"2"}>Tier 2 ({tier2})</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="status-filter-label">Filter by Status</InputLabel>
          <Select
            labelId="status-filter-label"
            value={statusFilter}
            label="Filter by Status"
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="Approved">Approved</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
          <Typography variant="body1" color="textSecondary" ml={2}>
            Loading factories...
          </Typography>
        </Box>
      ) : filteredFacilities.length > 0 ? (
        filteredFacilities.map((facility) => (
          <Paper
            key={facility?.factoryId}
            elevation={1}
            sx={{
              py: 3,
              px: 4,
              my: 4,
              boxShadow: "none",
              overflowY: "scroll",
              borderRadius: "12px",
              border: "1px solid #EAECF0",
              background: "#FFF",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              cursor: "pointer",
            }}
            onClick={() => {
              navigateToFacilityDetails(facility?.factoryId);
            }}
          >
            <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
              <Grid container sx={{ pr: 4, alignItems: "center" }}>
                <Grid item>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 18,
                      color: "#000",
                      display: "inline-block",
                      mr: 2,
                    }}
                  >
                    {facility?.name}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <ListCard data={facility} type="factory" />
          </Paper>
        ))
      ) : (
        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" color="textSecondary">
            No factories found.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FacilityComplianceList;
