import { all, put, select, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { getAnalyticsUrlStart, getAnalyticsUrlSuccess, getAnalyticsUrlFailure } from "../reducers/analytics.reducer";
import { selectAuthToken, selectOrganisationId } from "../selectors/login.selector";
import { ANALYTICS } from "../utils/api";
import { logout } from "../reducers/login.reducer";

function* getAnalyticsUrlWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = action.payload.organisationId;
  const dashboardId = action.payload.dashboardId;

  const requestURL = ANALYTICS.GET_URL
    .replace("<ORGANISATION_ID>", organisationId)
    .replace("<DASHBOARD_ID>", dashboardId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  try {
    const response = yield axios.get(requestURL, { headers });

    if (response && response.status === 200 && response.data) {
      const iframeUrl = response.data.results.iframeUrl;
      yield put(getAnalyticsUrlSuccess(iframeUrl));
    }
  } catch (error) {
    console.error("Failed to fetch analytics URL:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    yield put(getAnalyticsUrlFailure(error.message));
  }
}

function* watchGetAnalyticsUrlSaga() {
  yield takeLatest(getAnalyticsUrlStart.type, getAnalyticsUrlWorker);
}

export default function* rootSaga() {
  yield all([watchGetAnalyticsUrlSaga()]);
}