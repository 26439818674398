import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Divider,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectFactoryIssuesData } from "../../selectors/factory.selector";

const FacilityIssuesCard = ({ latestAuditData }) => {
  const issuesData = useSelector(selectFactoryIssuesData);
  const [filteredIssues, setFilteredIssues] = useState([]);

  useEffect(() => {
    const capDetails = latestAuditData?.metadata?.capDetails || [];
    setFilteredIssues(capDetails);
  }, [latestAuditData]);

  const handleSort = () => {
    const sortedData = [...filteredIssues].sort((a, b) =>
      a.subType.localeCompare(b.subType)
    );
    setFilteredIssues(sortedData);
  };

  const handleFilter = (key) => {
    const capDetails = latestAuditData?.metadata?.capDetails || [];
    const filteredData = capDetails.filter((issue) => issue.severity === key);
    setFilteredIssues(filteredData);
  };

  const aggregateIssues = (issues) => {
    const aggregated = {};
    issues.forEach((issue) => {
      if (!aggregated[issue.subType]) {
        aggregated[issue.subType] = { ZT: 0, Critical: 0, Major: 0, Minor: 0 };
      }
      aggregated[issue.subType][issue.severity] += 1;
    });
    return aggregated;
  };

  const aggregatedIssues = aggregateIssues(filteredIssues);

  const capDetailsLength = latestAuditData?.metadata?.capDetails?.length || 0;
  const severityCategories = latestAuditData?.metadata?.severityCategories || {};

  return (
    <Paper
      elevation={1}
      sx={{
        p: 3,
        borderRadius: "12px",
        border: "1px solid #EAECF0",
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        Issues by subcategory
      </Typography>
      <Divider sx={{ my: "12px" }} />
      <Box sx={{ height: 282, overflow: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ cursor: "default" }}>
              <TableCell
                sx={{
                  fontWeight: 500,
                  fontSize: 16,
                  borderBottom: "2px solid #3538CD",
                  cursor: "pointer",
                }}
                onClick={() => setFilteredIssues(latestAuditData?.metadata?.capDetails || [])}
              >
                <Tooltip title="Filter Total Issues" placement="top">
                  <Typography
                    sx={{ fontWeight: 500, fontSize: 16, color: "#8B8D97" }}
                  >
                    Total Issues
                  </Typography>
                </Tooltip>
                <Box>{capDetailsLength}</Box>
              </TableCell>
              {Object.entries(severityCategories).map(([key, color]) => (
                <TableCell
                  key={key}
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: color,
                    borderBottom: "2px solid #3538CD",
                    cursor: "pointer",
                  }}
                  onClick={() => handleFilter(key)}
                >
                  <Tooltip title={`Filter ${key}`} placement="top">
                    <span>{key}</span>
                  </Tooltip>
                  <Box>
                    {
                      (latestAuditData?.metadata?.capDetails || []).filter(
                        (issue) => issue.severity === key
                      ).length
                    }
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(aggregatedIssues).map(([subType, counts], index) => (
              <TableRow key={index} sx={{ cursor: "default" }}>
                <TableCell
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#45464E",
                    maxWidth: "90px",
                    pr: 0,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "normal",
                    cursor: "default",
                    borderBottom: "none",
                  }}
                >
                  {subType.length > 20 ? (
                    <Tooltip title={subType} placement="top">
                      <span>{subType}</span>
                    </Tooltip>
                  ) : (
                    subType
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#45464E",
                    borderBottom: "none",
                  }}
                >
                  {counts.ZT}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#45464E",
                    borderBottom: "none",
                  }}
                >
                  {counts.Critical}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#45464E",
                    borderBottom: "none",
                  }}
                >
                  {counts.Major}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#45464E",
                    borderBottom: "none",
                  }}
                >
                  {counts.Minor}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};

export default FacilityIssuesCard;
